import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  companies: [],
  company: { employees: [] },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isEmployeesLoading: false,
  isUploadingLogo: false,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_COMPANIES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_COMPANIES":
      return {
        ...state,
        companies: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_COMPANY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_COMPANY":
      return {
        ...state,
        company: action.response.data,
        isLoading: false,
      };
    case "PATCH_COMPANY_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_COMPANY":
      return {
        ...state,
        company: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_COMPANY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_COMPANY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_COMPANY":
      return {
        ...state,
        errors: false,
        company: action.response.data,
        isLoading: false,
      };

    case "POST_COMPANY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_COMPANY_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_COMPANY":
      return {
        ...state,
        companies: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_COMPANY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "COMPANY_NEW":
      return {
        ...state,
        errors: false,
        name: null,
        inn: null,
        website: null,
        city: null,
        postal_code: null,
        street: null, 
        building: null,
        employees: [],
        isLoading: false,
      };

    case "REQUEST_COMPANY_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });
    // pagination
    case "REQUEST_COMPANIES_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_COMPANIES_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    // general
    case "COMPANY_CHANGE_NAME":
      return update(state, {
        company: { name: { $set: action.value } },
      });

    case "COMPANY_CHANGE_INN":
      return update(state, {
        company: { inn: { $set: action.value } },
      });

    case "COMPANY_CHANGE_WEBSITE":
      return update(state, {
        company: { website: { $set: action.value } },
      });

    case "COMPANY_CHANGE_CITY":
      return update(state, {
        company: { city: { $set: action.value } },
      });

    case "COMPANY_CHANGE_POSTAL_CODE":
      return update(state, {
        company: { postal_code: { $set: action.value } },
      });

    case "COMPANY_CHANGE_STREET":
      return update(state, {
        company: { street: { $set: action.value } },
      });

    case "COMPANY_CHANGE_BUILDING":
      return update(state, {
        company: { building: { $set: action.value } },
      });

    // logo
    case "COMPANY_LOGO_UPLOAD":
      return update(state, {
        isUploadingLogo: { $set: true },
      });

    case "COMPANY_LOGO_UPLOAD_SUCCEED":
      return update(state, {
        company: { logo: { $set: action.response.data.logo } },
        errors: { $set: false },
        isUploadingLogo: { $set: false },
      });

    case "COMPANY_LOGO_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingLogo: { $set: false },
      });

    // user
    case "DELETE_COMPANY_USER_REQUEST":
      return {
        ...state,
        isEmployeesLoading: true,
        errors: false,
      };
    case "DELETE_COMPANY_USER":
      return update(state, {
        errors: { $set: false },
        isEmployeesLoading: { $set: false },
      });

    case "DELETE_COMPANY_USER_FAILURE":
      return {
        ...state,
        isEmployeesLoading: false,
        errors: true,
      };

    case "FETCH_COMPANY_USERS_REQUEST":
      return {
        ...state,
        isEmployeesLoading: true,
      };
    case "FETCH_COMPANY_USERS":
      return update(state, {
        company: { employees: { $set: action.response.data } },
        errors: { $set: false },
        isEmployeesLoading: { $set: false },
      });
    case "FETCH_COMPANY_USERS_FAILURE":
      return {
        ...state,
        errors: true,
        isEmployeesLoading: false,
      };

    case "POST_COMPANY_USER_REQUEST":
      return {
        ...state,
        errors: false,
        isEmployeesLoading: false,
      };
    case "POST_COMPANY_USER":
      return {
        ...state,
        errors: false,
        isEmployeesLoading: false,
      };

    case "POST_COMPANY_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isEmployeesLoading: false,
      };

    default:
      return state;
  }
}
