// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Actions from "../../../actions/admins";
import AdminsList from "./List";

class Admins extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchAdmins());
  };

  onDelete = id => {
    this.props
      .dispatch(Actions.onDelete(id))
      .then(() => this.props.dispatch(Actions.fetchAdmins()));
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchAdmins()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchAdmins()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchAdmins()));
  };

  // filters
  onChangeFilterActive = value => {
    this.props
      .dispatch(Actions.onChangeFilterActive(value))
      .then(() => this.props.dispatch(Actions.fetchAdmins()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchAdmins()));
  };

  render() {
    const { isLoading, admins, meta, fetchParams } = this.props;
    return (
      <AdminsList
        loading={isLoading}
        admins={admins}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSearch={this.onSearch}
        onChangeFilterActive={this.onChangeFilterActive}
        onSortBy={this.onSortBy}
      />
    );
  }
}

Admins.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  fetchParams: PropTypes.object,
  meta: PropTypes.object,
  dispatch: PropTypes.func,
  onDelete: PropTypes.func
};

const mapStateToProps = state => ({
  isLoading: state.admins.isLoading,
  admins: state.admins.admins,
  meta: state.admins.meta,
  fetchParams: state.admins.fetchParams
});

export default connect(mapStateToProps)(Admins);
