// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Drawer, Col, Row, Select, Form } from "antd";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const { Option } = Select;

class Filter extends Component {
  render() {
    const {
      visible,
      statuses,
      users,
      members,
      supportMessageTypes,
      onClose,
      onChangeStatuses,
      onChangeTypes,
      onChangeUsers,
      onChangeMembers,
    } = this.props;

    return (
      <Drawer
        width={540}
        visible={visible}
        title="Фильтры"
        placement={"right"}
        closable={false}
        onClose={onClose}
      >
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Статус"}
              name="statuses"
              initialValue={statuses ? statuses : []}
              onChange={onChangeStatuses}
            >
              <Select
                mode={"multiple"}
                style={{ width: "100%" }}
                placeholder="Статус"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={"1"}>новое</Option>
                <Option value={"2"}>в работе</Option>
                <Option value={"3"}>закрыто</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Тип"}
              name="users"
              initialValue={supportMessageTypes ? supportMessageTypes : []}
              onChange={onChangeTypes}
            >
              <SelectMultiFetch
                showSearch
                placeholder="Тип"
                onChange={onChangeTypes}
                selected={supportMessageTypes ? supportMessageTypes : []}
                url={"/support_message_types/search/?"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Отправители"}
              name="users"
              initialValue={users ? users : []}
              onChange={onChangeUsers}
            >
              <SelectMultiFetch
                showSearch
                placeholder="Отправители"
                onChange={onChangeUsers}
                selected={users ? users : []}
                url={"/users/search/?all='true'"}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Form.Item
              label={"Отправители (клиенты)"}
              name="members"
              initialValue={members ? members : []}
              onChange={onChangeMembers}
            >
              <SelectMultiFetch
                showSearch
                placeholder="Отправители (клиенты)"
                onChange={onChangeMembers}
                selected={members ? members : []}
                url={"/members/search/?all='true'"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Drawer>
    );
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Filter;
