import React from "react";
import { Link } from "react-router-dom";
import { Menu, Icon } from "antd";
import {
  DashboardOutlined,
  ApartmentOutlined,
  UserOutlined,
  AppstoreOutlined,
  QuestionOutlined,
  SettingOutlined,
  BlockOutlined,
  MessageOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { settings } from "accounting";

const SubMenu = Menu.SubMenu;

const commomRoutes = [
  {
    path: "/",
    exact: true,
    key: 10,
    name: "Рабочий стол",
    icon: <DashboardOutlined />,
    subroutes: [],
  },
  {
    path: "/organisations",
    key: 20,
    name: "Организации",
    icon: <ApartmentOutlined />,
    subroutes: [],
  },
  {
    path: "/tariff_plans",
    key: 30,
    name: "Тарифы",
    icon: <AppstoreOutlined />,
    subroutes: [],
  },
  {
    path: "/admins",
    key: 40,
    name: "Администраторы",
    icon: <UserOutlined />,
    subroutes: [],
  },
  {
    path: "/companies",
    key: 50,
    name: "Подрядчики",
    icon: <AuditOutlined />,
    subroutes: [],
  },
  {
    path: "/faqs",
    key: 60,
    name: "Система помощи",
    icon: <QuestionOutlined />,
    subroutes: [],
  },
  {
    path: null,
    key: 70,
    name: "Справочники",
    icon: <SettingOutlined />,
    subroutes: [
      {
        path: "/regions",
        key: 71,
        name: "Регионы",
        icon: <BlockOutlined />,
        subroutes: [],
      },
      {
        path: "/cities",
        key: 72,
        name: "Города",
        icon: <BlockOutlined />,
        subroutes: [],
      },
    ],
  },
  {
    path: null,
    key: 80,
    name: "Поддержка",
    icon: <MessageOutlined />,
    subroutes: [
      {
        isActive: true,
        path: "/support_messages",
        key: 71,
        name: "Сообщения",
        icon: <MessageOutlined />,
        subroutes: [],
      },
      {
        isActive: true,
        path: "/support_message_types",
        key: 72,
        name: "Типы сообщений",
        icon: <MessageOutlined />,
        subroutes: [],
      },
    ],
  },
];

function menuLeaf(item = {}) {
  if (item.subroutes.length > 0) {
    return (
      <SubMenu
        key={item.key}
        title={
          <span>
            {item.icon}
            <span>{item.name}</span>
          </span>
        }
      >
        {item.subroutes.map((child) => {
          return menuLeaf(child);
        })}
      </SubMenu>
    );
  } else {
    return (
      <Menu.Item key={item.key}>
        <Link to={item.path} className="nav-text">
          {item.icon}
          <span>{item.name}</span>
        </Link>
      </Menu.Item>
    );
  }
}

export default function MenuRoutes({ collapsed }) {
  const routes = commomRoutes;
  return (
    <Menu
      theme="dark"
      collapsed={collapsed}
      mode="inline"
      defaultSelectedKeys={["1"]}
    >
      {routes.map((item, i) => menuLeaf(item))}
    </Menu>
  );
}
