import { combineReducers } from "redux";
import session from "./session";
import currentAdmin from "./currentAdmin";
import organisations from "./organisations";
import companies from "./companies";
import admins from "./admins";
import tariffPlans from "./tariffPlans";
import faqs from "./faqs";
import regions from "./regions";
import cities from "./cities";
import supportMessages from './supportMessages';
import supportMessageTypes from './supportMessageTypes';

const appReducer = combineReducers({
  session,
  currentAdmin,
  organisations,
  companies,
  admins,
  tariffPlans,
  faqs,
  regions,
  cities,
  supportMessages,
  supportMessageTypes
});

export default function (state, action) {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
}
