// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, PageHeader, List, Avatar, Space, Tag, Tooltip } from "antd";
import { MessageOutlined, EyeOutlined, StarOutlined } from "@ant-design/icons";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

function faqTag(tags) {
  return tags.map((tag) => {
    return (
      <Tag key={tag.id} color="#108ee9">
        {tag.name}
      </Tag>
    );
  });
}

class FaqsList extends Component {
  constructor(props) {
    super(props);
  }

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  render() {
    const {
      loading,
      faqs,
      meta,
      fetchParams,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
    } = this.props;

    let mainButton = (
      <Link
        key="1"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/faqs/new` }}
      >
        Создать
      </Link>
    );

    const IconText = ({ icon, text }) => (
      <Space>
        {React.createElement(icon)}
        {text}
      </Space>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Помощь`}
        extra={[mainButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <List
                itemLayout="vertical"
                size="large"
                pagination={{
                  defaultPageSize: fetchParams.limit,
                  defaultCurrent: meta.current_page,
                  onChange: onChangePage,
                  onShowSizeChange: onChangeLimit,
                  total: meta.total_count,
                }}
                dataSource={faqs}
                footer={null}
                renderItem={(item) => (
                  <List.Item
                    key={item.id}
                    actions={[
                      <Tooltip
                        key={`views-${item.id}`}
                        placement="top"
                        title={"Количество просмотров"}
                      >
                        <IconText icon={EyeOutlined} text={item.views} />
                      </Tooltip>,
                      <Space>
                        <span style={{ textAlign: "right" }}>
                          {faqTag(item.tags)}
                        </span>
                      </Space>,
                    ]}
                    extra={[moment(item.created_at).format("L")]}
                  >
                    <List.Item.Meta
                      avatar={<Avatar>{item.application_type}</Avatar>}
                      title={
                        <Link to={`/faqs/${item.id}/edit`}>{item.title}</Link>
                      }
                      // description={item.body}
                    />
                    {item.published_at ? `Опубликовано: ${moment(item.published_at).format("LLL")} ${item.admin ? item.admin.name : null}` : null}
                  </List.Item>
                )}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

FaqsList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default FaqsList;
