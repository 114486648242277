// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/tariffPlans";
import LoadingSpin from "../../../components/LoadingSpin";
import Form from "./Form";

class TariffPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchTariffPlan(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/tariff_plans/${this.props.tariffPlan.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/tariff_plans`);
  };

  onChangeActive = (e) => {
    this.props.dispatch(Actions.onChangeActive(e.target.checked));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDescription = (e) => {
    this.props.dispatch(Actions.onChangeDescription(e.target.value));
  };

  onChangePrice = (e) => {
    this.props.dispatch(Actions.onChangePrice(e.target.value));
  };

  onChangePricePerUser = (e) => {
    this.props.dispatch(Actions.onChangePricePerUser(e.target.value));
  };

  onChangeUsersQty = (e) => {
    this.props.dispatch(Actions.onChangeUsersQty(e.target.value));
  };

  // services
  onChangeTickets = e => {
    this.props.dispatch(Actions.onChangeTickets(e.target.checked));
  };

  onChangeTicketReplaces = e => {
    this.props.dispatch(Actions.onChangeTicketReplaces(e.target.checked));
  };

  onChangeEntityTasks = e => {
    this.props.dispatch(Actions.onChangeEntityTasks(e.target.checked));
  };

  onChangeOrderTasks = e => {
    this.props.dispatch(Actions.onChangeOrderTasks(e.target.checked));
  };

  onChangeOrders = e => {
    this.props.dispatch(Actions.onChangeOrders(e.target.checked));
  };

  onChangeTasks = e => {
    this.props.dispatch(Actions.onChangeTasks(e.target.checked));
  };

  onChangeProjects = e => {
    this.props.dispatch(Actions.onChangeProjects(e.target.checked));
  };

  onChangePayrolls = e => {
    this.props.dispatch(Actions.onChangePayrolls(e.target.checked));
  };
  onChangeAmount = e => {
    this.props.dispatch(Actions.onChangeAmount(e.target.checked));
  };

  render() {
    const { isLoading, errors, tariffPlan } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isNew={this.state.isNew}
        tariffPlan={tariffPlan}
        onSave={this.onSave}
        onClose={this.onClose}
        onUploadAvatar={this.onUploadAvatar}
        onChangeActive={this.onChangeActive}
        onChangeName={this.onChangeName}
        onChangeDescription={this.onChangeDescription}
        onChangePrice={this.onChangePrice}
        onChangePricePerUser={this.onChangePricePerUser}
        onChangeUsersQty={this.onChangeUsersQty}
        // services
        onChangeTickets={this.onChangeTickets}
        onChangeTicketReplaces={this.onChangeTicketReplaces}
        onChangeEntityTasks={this.onChangeEntityTasks}
        onChangeOrderTasks={this.onChangeOrderTasks}
        onChangeOrders={this.onChangeOrders}
        onChangeTasks={this.onChangeTasks}
        onChangeProjects={this.onChangeProjects}
        onChangePayrolls={this.onChangePayrolls}
        onChangeAmount={this.onChangeAmount}
      />
    );
  }
}

TariffPlan.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  tariffPlan: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.tariffPlans.isLoading,
  errors: state.tariffPlans.errors,
  tariffPlan: state.tariffPlans.tariffPlan,
});

export default connect(mapStateToProps)(withRouter(TariffPlan));
