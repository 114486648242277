// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Sidebar from "./Sidebar";
import moment from "moment";
import "moment/locale/ru";

class FaqForm extends Component {
  formRef = React.createRef();
  onSubmit = (e) => {
    e.preventDefault();
    this.formRef.current.validateFields().then((values) => {
      this.props.onSave(values);
    });
  };

  render() {
    const {
      isNew,
      errors,
      isLoading,
      currentUser,
      faq,
      onClose,
      // general
      onChangeTitle,
      onChangeBody,
      onChangeTags,
      // sidebar
      onChangePublished,
      onChangeApplicationType,
      onChangeContex,
      onChangeSubcontext,
    } = this.props;


    return (
      <Form ref={this.formRef} layout={"vertical"} onFinish={this.onSubmit}>
        <Card
          loading={isLoading}
          title={
            <h2 style={{ marginBottom: 0 }}>{`Статья #${
              isNew ? "новая" : faq.id
            } от ${moment(faq.created_at).format("LLL")}`}</h2>
          }
          extra={[
            <Button key={1} onClick={onClose}>
              Закрыть
            </Button>,
            <Button
              key={2}
              onClick={this.onSubmit}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>,
          ]}
        >
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={16}>
              <Header
                title={faq.title}
                body={faq.body}
                tags={faq.tags}
                onChangeTitle={onChangeTitle}
                onChangeBody={onChangeBody}
                onChangeTags={onChangeTags}
              />
            </Col>
            <Col span={8}>
              <Sidebar
                isPublished={faq.published_at ? true : false}
                applicationType={faq.application_type}
                contex={faq.contex}
                subcontext={faq.subcontext}
                onChangePublished={onChangePublished}
                onChangeApplicationType={onChangeApplicationType}
                onChangeContex={onChangeContex}
                onChangeSubcontext={onChangeSubcontext}
              />
            </Col>
          </Row>
        </Card>
      </Form>
    );
  }
}

FaqForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  faq: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
};

export default FaqForm;
