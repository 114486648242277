import api from "../api";

const Actions = {};

Actions.fetchTariffPlans = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_TARIFF_PLANS_REQUEST" });
    const params = getState().tariffPlans.fetchParams;
    api
      .fetch("/tariff_plans", params)
      .then(response => {
        dispatch({ type: "FETCH_TARIFF_PLANS", response });
      })
      .catch(error => {
        if (error.response.status == 500) {
          error.response.data.errors.map(error => {
            return api.openNotification("error", "Тарифный план", error);
          });
        }
        dispatch({ type: "FETCH_TARIFF_PLANS_FAILURE", error });
      });
  };
};

Actions.fetchTariffPlan = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_TARIFF_PLAN_REQUEST" });
    api
      .fetch(`/tariff_plans/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_TARIFF_PLAN", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Тарифный план", error);
          });
        }
        dispatch({ type: "FETCH_TARIFF_PLAN_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_NEW"
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_TARIFF_PLAN_REQUEST" });
    const tariffPlan = getState().tariffPlans.tariffPlan;
    const data = {
      tariff_plan: {
        is_active: tariffPlan.is_active,
        position: tariffPlan.position,
        name: tariffPlan.name,
        description: tariffPlan.description,
        is_api_available: tariffPlan.is_api_available,
        services: tariffPlan.services,
        users_qty: tariffPlan.users_qty,
        price_per_user: tariffPlan.price_per_user,
        customers_qty: tariffPlan.customers_qty,
        price: tariffPlan.price,
      }
    };
    api
      .patch(`/tariff_plans/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_TARIFF_PLAN", response });
        api.openNotification("success", "Тарифный план", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Тарифный план", error);
          });
        }
        dispatch({ type: "PATCH_TARIFF_PLAN_FAILURE", response });
      });
  };
};



Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_TARIFF_PLAN_REQUEST" });
    const tariffPlan = getState().tariffPlans.tariffPlan;
    const data = {
      tariff_plan: {
        is_active: tariffPlan.is_active,
        position: tariffPlan.position,
        name: tariffPlan.name,
        description: tariffPlan.description,
        is_api_available: tariffPlan.is_api_available,
        services: tariffPlan.services,
        users_qty: tariffPlan.users_qty,
        price_per_user: tariffPlan.price_per_user,
        customers_qty: tariffPlan.customers_qty,
        price: tariffPlan.price,
      }
    };
    return api
      .post(`/tariff_plans`, data)
      .then(response => {
        dispatch({ type: "POST_TARIFF_PLAN", response });
        api.openNotification(
          "success",
          "Тарифный план системы",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Тарифный план системы", error);
          });
        }
        dispatch({ type: "POST_TARIFF_PLAN_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_TARIFF_PLAN_REQUEST" });
    return api
      .delete(`/tariff_plans/${id}`)
      .then(response => {
        api.openNotification("success", "Тарифный план", "Успешно удален!");
        dispatch({ type: "DELETE_TARIFF_PLAN", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Тарифный план", error);
          });
        }
        dispatch({
          type: "DELETE_TARIFF_PLAN_FAILURE"
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_TARIFF_PLAN_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_TARIFF_PLAN_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_TARIFF_PLAN_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_TARIFF_PLANS_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLANS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_ACTIVE",
      value: value
    });
  };
};


Actions.onChangeRole = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_ROLE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeDescription = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_DESCRIPTION",
      value: value
    });
  };
};

Actions.onChangePrice = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_PRICE",
      value: value
    });
  };
};

Actions.onChangePricePerUser = value => {
    return dispatch => {
      dispatch({
        type: "TARIFF_PLAN_CHANGE_PRICE_PER_USER",
        value: value
      });
    };
  };

  Actions.onChangeUsersQty = value => {
    return dispatch => {
      dispatch({
        type: "TARIFF_PLAN_CHANGE_USERS_QTY",
        value: value
      });
    };
  };

// services
Actions.onChangeTickets = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_TICKETS",
      value: value
    });
  };
};

Actions.onChangeTicketReplaces = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_TICKET_REPLACES",
      value: value
    });
  };
};

Actions.onChangeEntityTasks = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_ENTITY_TASKS",
      value: value
    });
  };
};

Actions.onChangeOrderTasks = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_ORDER_TASKS",
      value: value
    });
  };
};

Actions.onChangeOrders = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_ORDERS",
      value: value
    });
  };
};

Actions.onChangeTasks = value => {
    return dispatch => {
      dispatch({
        type: "TARIFF_PLAN_CHANGE_SERVICES_TASKS",
        value: value
      });
    };
  };

Actions.onChangeProjects = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_PROJECTS",
      value: value
    });
  };
};

Actions.onChangePayrolls = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_PAYROLLS",
      value: value
    });
  };
};

Actions.onChangeAmount = value => {
  return dispatch => {
    dispatch({
      type: "TARIFF_PLAN_CHANGE_SERVICES_AMOUNT",
      value: value
    });
  };
};


export default Actions;
