// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/regions";
import { Spin } from "antd";
import Form from "./Form";

class Region extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchRegion(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/regions/${this.props.region.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/regions`);
  };

  onChangeCode = (e) => {
    this.props.dispatch(Actions.onChangeCode(e.target.value));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeRegionType = (e) => {
    this.props.dispatch(Actions.onChangeRegionType(e.target.value));
  };

  onChangeFullName = (e) => {
    this.props.dispatch(Actions.onChangeFullName(e.target.value));
  };

  onChangeFederalDistrict = (e) => {
    this.props.dispatch(Actions.onChangeFederalDistrict(e.target.value));
  };

  onChangeKladr = (e) => {
    this.props.dispatch(Actions.onChangeKladr(e.target.value));
  };

  onChangeFias = (e) => {
    this.props.dispatch(Actions.onChangeFias(e.target.value));
  };

  onChangeOkato = (e) => {
    this.props.dispatch(Actions.onChangeOkato(e.target.value));
  };

  onChangeTimeZone = (value) => {
    this.props.dispatch(Actions.onChangeTimeZone(value));
  };

  onChangeLat = (value) => {
    this.props.dispatch(Actions.onChangeLat(value));
  };

  onChangeLon = (value) => {
    this.props.dispatch(Actions.onChangeLon(value));
  };

  render() {
    const { isNew } = this.state;
    const { isLoading, region } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={isNew}
        isLoading={isLoading}
        region={region}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeCode={this.onChangeCode}
        onChangeName={this.onChangeName}
        onChangeRegionType={this.onChangeRegionType}
        onChangeFullName={this.onChangeFullName}
        onChangeFederalDistrict={this.onChangeFederalDistrict}
        onChangeKladr={this.onChangeKladr}
        onChangeFias={this.onChangeFias}
        onChangeOkato={this.onChangeOkato}
        onChangeTimeZone={this.onChangeTimeZone}
        onChangeLat={this.onChangeLat}
        onChangeLon={this.onChangeLon}
      />
    );
  }
}

Region.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  region: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.regions.isLoading,
  isModalVisible: state.regions.isModalVisible,
  errors: state.regions.errors,
  region: state.regions.region,
});

export default connect(mapStateToProps)(withRouter(Region));
