import api from "../api";

const Actions = {};

Actions.fetchRegions = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_REGIONS_REQUEST" });
    const params = getState().regions.fetchParams;
    api
      .fetch("/regions", params)
      .then((response) => {
        dispatch({ type: "FETCH_REGIONS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_REGIONS_FAILURE", response });
      });
  };
};

Actions.fetchRegion = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_REGION_REQUEST" });
    api
      .fetch(`/regions/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_REGION", response });
      })
      .catch((error) => {
        api.handleError(error, "Регион");
        dispatch({ type: "FETCH_REGION_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "REGION_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_REGION_REQUEST" });
    const region = getState().regions.region;
    const data = {
      region: {
        code: region.code,
        name: region.name,
        region_type: region.region_type,
        full_name: region.full_name,
        federal_district: region.federal_district,
        kladr_id: region.kladr_id,
        fias_id: region.fias_id,
        okato: region.okato,
        time_zone: region.time_zone,
        lat: region.lat,
        lon: region.lon,
      },
    };
    api
      .patch(`/regions/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_REGION", response });
        api.openNotification("success", "Регион", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Регион", error);
          });
        }
        dispatch({ type: "PATCH_REGION_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_REGION_REQUEST" });
    const region = getState().regions.region;
    const data = {
      region: {
        code: region.code,
        name: region.name,
        region_type: region.region_type,
        full_name: region.full_name,
        federal_district: region.federal_district,
        kladr_id: region.kladr_id,
        fias_id: region.fias_id,
        okato: region.okato,
        time_zone: region.time_zone,
        lat: region.lat,
        lon: region.lon,
      },
    };
    return api
      .post(`/regions`, data)
      .then((response) => {
        dispatch({ type: "POST_REGION", response });
        api.openNotification(
          "success",
          "Регион",
          "Успешно создан!"
        );
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Регион", error);
          });
        }
        dispatch({ type: "POST_REGION_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_REGION_REQUEST" });
    return api
      .delete(`/regions/${id}`)
      .then((response) => {
        api.openNotification("success", "Регион", "Успешно удален!");
        dispatch({ type: "DELETE_REGION", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Регион", error);
          });
        }
        dispatch({
          type: "DELETE_REGION_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REGION_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REGION_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "REGIONS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};


Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_REGION_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "REGIONS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_CODE",
      value: value,
    });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeRegionType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_REGION_TYPE",
      value: value,
    });
  };
};

Actions.onChangeFullName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_FULL_NAME",
      value: value,
    });
  };
};

Actions.onChangeFederalDistrict = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_FEDERAL_DISTRICT",
      value: value,
    });
  };
};

Actions.onChangeKladr = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_KLADR",
      value: value,
    });
  };
};

Actions.onChangeFias = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_FIAS",
      value: value,
    });
  };
};

Actions.onChangeOkato = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_OKATO",
      value: value,
    });
  };
};

Actions.onChangeTimeZone = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_TIME_ZONE",
      value: value,
    });
  };
};

Actions.onChangeLat = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_LAT",
      value: value,
    });
  };
};

Actions.onChangeLon = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REGION_CHANGE_LON",
      value: value,
    });
  };
};

export default Actions;
