import api from "../api";

const Actions = {};

Actions.fetchCities = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_CITIES_REQUEST" });
    let regions = [];
    let areas = [];
    const params = getState().cities.fetchParams;

    if (params.regions) {
      regions = params.regions.map((region) => {
        if (region && region.id) {
          return region.id;
        }
      });
    }

    if (params.areas) {
      areas = params.areas.map((area) => {
        if (area && area.id) {
          return area.id;
        }
      });
    }

    const data = {
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      regions: regions,
      areas: areas,
    };

    api
      .fetch("/cities", data)
      .then((response) => {
        dispatch({ type: "FETCH_CITIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_CITIES_FAILURE", response });
      });
  };
};

Actions.fetchCity = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_CITY_REQUEST" });
    api
      .fetch(`/cities/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_CITY", response });
      })
      .catch((error) => {
        api.handleError(error, "Город");
        dispatch({ type: "FETCH_CITY_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "CITY_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_CITY_REQUEST" });
    const city = getState().cities.city;
    const data = {
      city: {
        region_id: city.region ? city.region.id : null,
        area_id: city.area ? city.area.id : null,
        name: city.name,
        city_type: city.city_type,
        full_name: city.full_name,
        kladr_id: city.kladr_id,
        fias_id: city.fias_id,
        okato: city.okato,
        lat: city.lat,
        lon: city.lon,
      },
    };
    api
      .patch(`/cities/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_CITY", response });
        api.openNotification("success", "Город", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Город", error);
          });
        }
        dispatch({ type: "PATCH_CITY_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_CITY_REQUEST" });
    const city = getState().cities.city;
    const data = {
      city: {
        region_id: city.region ? city.region.id : null,
        area_id: city.area ? city.area.id : null,
        name: city.name,
        city_type: city.city_type,
        full_name: city.full_name,
        kladr_id: city.kladr_id,
        fias_id: city.fias_id,
        okato: city.okato,
        lat: city.lat,
        lon: city.lon,
      },
    };
    return api
      .post(`/cities`, data)
      .then((response) => {
        dispatch({ type: "POST_CITY", response });
        api.openNotification("success", "Город", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Город", error);
          });
        }
        dispatch({ type: "POST_CITY_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_CITY_REQUEST" });
    return api
      .delete(`/cities/${id}`)
      .then((response) => {
        api.openNotification("success", "Город", "Успешно удален!");
        dispatch({ type: "DELETE_CITY", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Город", error);
          });
        }
        dispatch({
          type: "DELETE_CITY_FAILURE",
        });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_CITY_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_CITY_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "CITIES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_CITY_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "CITIES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeRegion = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_REGION",
      value: value,
    });
  };
};

Actions.onChangeArea = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_AREA",
      value: value,
    });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeCityType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_CITY_TYPE",
      value: value,
    });
  };
};

Actions.onChangeFullName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_FULL_NAME",
      value: value,
    });
  };
};

Actions.onChangeKladr = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_KLADR",
      value: value,
    });
  };
};

Actions.onChangeFias = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_FIAS",
      value: value,
    });
  };
};

Actions.onChangeOkato = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_OKATO",
      value: value,
    });
  };
};

Actions.onChangeLat = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_LAT",
      value: value,
    });
  };
};

Actions.onChangeLon = (value) => {
  return (dispatch) => {
    dispatch({
      type: "CITY_CHANGE_LON",
      value: value,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "CITIES_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "CITIES_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterRegions = (values) => {
  return (dispatch) => {
    dispatch({
      type: "CITIES_FILTER_REGIONS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterAreas = (values) => {
  return (dispatch) => {
    dispatch({
      type: "CITIES_FILTER_AREAS",
      values: values,
    });
    return Promise.resolve();
  };
};

export default Actions;
