import api from "../api";

const Actions = {};

Actions.fetchSupportMessages = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGES_REQUEST" });
    let users = [];
    let members = [];
    let support_message_types = [];
    let params = getState().supportMessages.fetchParams;

    if (params.users) {
      users = params.users.map((user) => {
        if (user && user.id) {
          return user.id;
        }
      });
    }

    if (params.members) {
      members = params.members.map((member) => {
        if (member && member.id) {
          return member.id;
        }
      });
    }

    if (params.support_message_types) {
      support_message_types = params.support_message_types.map(
        (support_message_type) => {
          if (support_message_type && support_message_type.id) {
            return support_message_type.id;
          }
        }
      );
    }

    const data = {
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
      statuses: params.statuses,
      users: users,
      members: members,
      support_message_types: support_message_types,
    };

    api
      .fetch("/support_messages", data)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SUPPORT_MESSAGES_FAILURE", response });
      });
  };
};

Actions.fetchSupportMessage = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_REQUEST" });
    api
      .fetch(`/support_messages/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE", response });
      })
      .catch((error) => {
        api.handleError(error, "Сообщение");
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_FAILURE" });
      });
  };
};

// messages
Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_SUPPORT_MESSAGE_REQUEST" });
    const message = getState().supportMessages.supportMessage;
    const data = {
      support_message: {
        status: message.status,
        support_message_type_id: message.support_message_type
          ? message.support_message_type.id
          : null,
        subject: message.subject,
        description: message.description,
      },
    };

    api
      .patch(`/support_messages/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_SUPPORT_MESSAGE", response });
        api.openNotification("success", "Сообщение", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Заказ", error);
          });
        }
        dispatch({ type: "PATCH_SUPPORT_MESSAGE_FAILURE", response });
      });
  };
};

Actions.onCreate = (message) => {
  console.log(message);
  return (dispatch) => {
    dispatch({ type: "POST_SUPPORT_MESSAGE_REQUEST" });

    const formData = new FormData();
    formData.append(
      `support_message[support_message_type_id]`,
      message.support_message_type ? message.support_message_type.id : null
    );
    formData.append(`support_message[subject]`, message.subject);
    formData.append(`support_message[description]`, message.description);
    formData.append(`location`, message.url);
    formData.append(`userAgent`, navigator.userAgent);

    if (message.attachments.length > 0) {
      message.attachments.map((item) => {
        formData.append("files[]", item);
      });
    }

    return api
      .postFormData(`/support_messages`, formData)
      .then((response) => {
        dispatch({ type: "POST_SUPPORT_MESSAGE_SUCCEED", response });
        api.openNotification("success", "Сообщение", "Успешно создано!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Сообщение", error);
          });
        }
        dispatch({ type: "POST_SUPPORT_MESSAGE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_SUPPORT_MESSAGE_REQUEST" });
    return api
      .delete(`/support_messages/${id}`)
      .then((response) => {
        api.openNotification("success", "Сообщение", "Успешно удалено!");
        dispatch({ type: "DELETE_SUPPORT_MESSAGE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Сообщение", error);
          });
        }
        dispatch({
          type: "DELETE_SUPPORT_MESSAGE_FAILURE",
        });
      });
  };
};

// comments
Actions.fetchSupportMessageComments = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_COMMENTS_REQUEST" });
    api
      .fetch(`/support_messages/${id}/support_message_comments`)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_COMMENTS", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Статус", error);
          });
        }
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_COMMENTS_FAILURE", response });
      });
  };
};

Actions.onCreateComment = (id, value) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_SUPPORT_MESSAGE_COMMENT_REQUEST" });
    return api
      .post(`/support_messages/${id}/support_message_comments`, {
        support_message_comment: { comment: value },
      })
      .then((response) => {
        dispatch({ type: "POST_SUPPORT_MESSAGE_COMMENT", response });
        api.openNotification("success", "Комментарий", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Комментарий", error);
          });
        }
        dispatch({ type: "POST_SUPPORT_MESSAGE_COMMENT_FAILURE", response });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_ASSIGNEES_REQUEST" });
    api
      .fetch(`/support_messages/${id}/support_message_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_ASSIGNEES_FAILURE", response });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_SUPPORT_MESSAGE_ASSIGNEE_REQUEST" });
    return api
      .post(`/support_messages/${id}/support_message_assignees`, {
        support_message_assignee: {
          assignee_type: "admin",
          admin_id: assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_SUPPORT_MESSAGE_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "POST_SUPPORT_MESSAGE_ASSIGNEE_FAILURE", response });
      });
  };
};

Actions.onDeleteAssignee = (support_message_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(
        `/support_messages/${support_message_id}/support_message_assignees/${id}`
      )
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({ type: "SUPPORT_MESSAGE_ASSIGNEE_DELETE_SUCCEED", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "SUPPORT_MESSAGE_ASSIGNEE_DELETE_FAILED" });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeSubject = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_CHANGE_SUBJECT",
      value: value,
    });
  };
};

Actions.onChangeDescription = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_CHANGE_DESCRIPTION",
      value: value,
    });
  };
};

Actions.onChangeStatus = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_CHANGE_STATUS",
      value: value,
    });
  };
};

Actions.onChangeSupportMessageType = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_CHANGE_SUPPORT_MESSAGE_TYPE",
      value: value,
    });
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterStatuses = (values) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_FILTER_STATUSES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterTypes = (values) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_FILTER_TYPES",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterUsers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_FILTER_USERS",
      values: values,
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterMembers = (values) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_FILTER_MEMBERS",
      values: values,
    });
    return Promise.resolve();
  };
};

export default Actions;
