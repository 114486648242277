/// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../actions/session";

import Dashboard from "../Dashboard";
import MainLayout from "../Layout";
import NotFound from "../../components/NotFound";
import Login from "../Login";

import Organisations from "../Organisations/List";
import Organisation from "../Organisations/Form";

import Companies from "../Companies/List";
import Company from "../Companies/Form";

import Admins from "../Admins/List";
import Admin from "../Admins/Form";

import TariffPlans from "../TariffPlans/List";
import TariffPlan from "../TariffPlans/Form";

import Faqs from "../Faqs/List";
import Faq from "../Faqs/Form";

import Regions from "../Regions/List";
import Region from "../Regions/Form";

import Cities from "../Cities/List";
import City from "../Cities/Form";

import SupportMessages from "../SupportMessages/List";
import SupportMessage from "../SupportMessages/Form";

import SupportMessageTypes from "../SupportMessageTypes/List";
import SupportMessageType from "../SupportMessageTypes/Form";

class App extends Component {
  componentDidMount() {
    const token = localStorage.getItem("token");
    if (token) {
      this.props.dispatch(Actions.authenticate());
    } else {
      this.props.dispatch(Actions.unauthenticate());
    }
  }

  renderRoutes = (isAuthenticated) => {
    const PrivateRoute = ({ component: Component, ...rest }) => (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{
                pathname: "/auth/login",
                state: { from: props.location },
              }}
            />
          )
        }
      />
    );

    return (
      <Router>
        <Switch>
          <Route path="/auth/login" component={Login} />
          <MainLayout>
            <Switch>
              <PrivateRoute
                isAuthenticated
                path="/"
                exact
                component={Dashboard}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisations"
                exact
                component={Organisations}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisations/new"
                exact
                component={Organisation}
              />

              <PrivateRoute
                isAuthenticated
                path="/organisations/:id/edit"
                exact
                component={Organisation}
              />

              <PrivateRoute
                isAuthenticated
                path="/companies"
                exact
                component={Companies}
              />

              <PrivateRoute
                isAuthenticated
                path="/companies/new"
                exact
                component={Company}
              />

              <PrivateRoute
                isAuthenticated
                path="/companies/:id/edit"
                exact
                component={Company}
              />

              <PrivateRoute
                isAuthenticated
                path="/admins"
                exact
                component={Admins}
              />

              <PrivateRoute
                isAuthenticated
                path="/admins/new"
                exact
                component={Admin}
              />

              <PrivateRoute
                isAuthenticated
                path="/admins/:id/edit"
                exact
                component={Admin}
              />

              <PrivateRoute
                isAuthenticated
                path="/tariff_plans"
                exact
                component={TariffPlans}
              />

              <PrivateRoute
                isAuthenticated
                path="/tariff_plans/new"
                exact
                component={TariffPlan}
              />

              <PrivateRoute
                isAuthenticated
                path="/tariff_plans/:id/edit"
                exact
                component={TariffPlan}
              />

              <PrivateRoute
                isAuthenticated
                path="/faqs"
                exact
                component={Faqs}
              />

              <PrivateRoute
                isAuthenticated
                path="/faqs/new"
                exact
                component={Faq}
              />

              <PrivateRoute
                isAuthenticated
                path="/faqs/:id/edit"
                exact
                component={Faq}
              />

              <PrivateRoute
                isAuthenticated
                path="/regions"
                exact
                component={Regions}
              />

              <PrivateRoute
                isAuthenticated
                path="/regions/new"
                exact
                component={Region}
              />

              <PrivateRoute
                isAuthenticated
                path="/regions/:id/edit"
                exact
                component={Region}
              />

              <PrivateRoute
                isAuthenticated
                path="/cities"
                exact
                component={Cities}
              />

              <PrivateRoute
                isAuthenticated
                path="/cities/new"
                exact
                component={City}
              />

              <PrivateRoute
                isAuthenticated
                path="/cities/:id/edit"
                exact
                component={City}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_message_types"
                exact
                component={SupportMessageTypes}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_message_types/new"
                exact
                component={SupportMessageType}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_message_types/:id/edit"
                exact
                component={SupportMessageType}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages"
                exact
                component={SupportMessages}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/new"
                exact
                component={SupportMessage}
              />

              <PrivateRoute
                isAuthenticated
                path="/support_messages/:id/edit"
                exact
                component={SupportMessage}
              />

              <Route component={NotFound} />
            </Switch>
          </MainLayout>
        </Switch>
      </Router>
    );
  };

  render() {
    return (
      <div>
        {this.props.isLoading
          ? null
          : this.renderRoutes(this.props.isAuthenticated)}
      </div>
    );
  }
}

App.propTypes = {
  dispatch: PropTypes.func,
  isAuthenticated: PropTypes.bool.isRequired,
  currentAdmin: PropTypes.object,
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.session.isAuthenticated,
  isLoading: state.session.isLoading,
});
export default connect(mapStateToProps)(App);
