import update from "immutability-helper";

const initialState = {
  regions: [],
  region: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_REGIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_REGIONS":
      return {
        ...state,
        regions: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_REGION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_REGION":
      return {
        ...state,
        region: action.response.data,
        isLoading: false,
      };

    case "PATCH_REGION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "PATCH_REGION":
      return {
        ...state,
        region: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_REGION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_REGION_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };

    case "POST_REGION":
      return {
        ...state,
        errors: false,
        region: action.response.data,
        isLoading: false,
      };

    case "POST_REGION_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_REGION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "DELETE_REGION":
      return {
        ...state,
        regions: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "DELETE_REGION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "REGION_NEW":
      return {
        ...state,
        errors: false,
        region: {
          code: null,
          name: null,
          region_type: null,
          full_name: null,
          federal_district: null,
          kladr_id: null,
          fias_id: null,
          okato: null,
          time_zone: 0,
        },
        isLoading: false,
      };

    case "REQUEST_REGION_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_REGION_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_REGION_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_REGIONS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } },
      });

    case "REQUEST_REGIONS_FILTER_CUSTOMER":
      return update(state, {
        fetchParams: { customer_id: { $set: action.value } },
      });

    case "REGIONS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "REGION_CHANGE_CODE":
      return update(state, {
        region: { code: { $set: action.value } },
      });

    case "REGION_CHANGE_NAME":
      return update(state, {
        region: { name: { $set: action.value } },
      });

    case "REGION_CHANGE_REGION_TYPE":
      return update(state, {
        region: { region_type: { $set: action.value } },
      });

    case "REGION_CHANGE_FULL_NAME":
      return update(state, {
        region: { full_name: { $set: action.value } },
      });

    case "REGION_CHANGE_FEDERAL_DISTRICT":
      return update(state, {
        region: { federal_district: { $set: action.value } },
      });

    case "REGION_CHANGE_KLADR":
      return update(state, {
        region: { kladr_id: { $set: action.value } },
      });

    case "REGION_CHANGE_FIAS":
      return update(state, {
        region: { fias_id: { $set: action.value } },
      });

    case "REGION_CHANGE_OKATO":
      return update(state, {
        region: { okato: { $set: action.value } },
      });

    case "REGION_CHANGE_TIME_ZONE":
      return update(state, {
        region: { time_zone: { $set: action.value } },
      });

    case "REGION_CHANGE_LAT":
      return update(state, {
        region: { lat: { $set: action.value } },
      });

    case "REGION_CHANGE_LON":
      return update(state, {
        region: { lon: { $set: action.value } },
      });

    default:
      return state;
  }
}
