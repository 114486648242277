// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/cities";
import { Spin } from "antd";
import Form from "./Form";

class City extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchCity(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/cities/${this.props.city.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/cities`);
  };

  onChangeRegion = (value) => {
    this.props.dispatch(Actions.onChangeRegion(value));
  };

  onChangeArea = (value) => {
    this.props.dispatch(Actions.onChangeArea(value));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeCityType = (e) => {
    this.props.dispatch(Actions.onChangeCityType(e.target.value));
  };

  onChangeFullName = (e) => {
    this.props.dispatch(Actions.onChangeFullName(e.target.value));
  };

  onChangeKladr = (e) => {
    this.props.dispatch(Actions.onChangeKladr(e.target.value));
  };

  onChangeFias = (e) => {
    this.props.dispatch(Actions.onChangeFias(e.target.value));
  };

  onChangeOkato = (e) => {
    this.props.dispatch(Actions.onChangeOkato(e.target.value));
  };

  onChangeLat = (value) => {
    this.props.dispatch(Actions.onChangeLat(value));
  };

  onChangeLon = (value) => {
    this.props.dispatch(Actions.onChangeLon(value));
  };

  render() {
    const { isNew } = this.state;
    const { isLoading, city } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={isNew}
        isLoading={isLoading}
        city={city}
        onSave={this.onSave}
        onClose={this.onClose}
        onChangeRegion={this.onChangeRegion}
        onChangeArea={this.onChangeArea}
        onChangeName={this.onChangeName}
        onChangeCityType={this.onChangeCityType}
        onChangeFullName={this.onChangeFullName}
        onChangeKladr={this.onChangeKladr}
        onChangeFias={this.onChangeFias}
        onChangeOkato={this.onChangeOkato}
        onChangeLat={this.onChangeLat}
        onChangeLon={this.onChangeLon}
      />
    );
  }
}

City.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  city: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.cities.isLoading,
  isModalVisible: state.cities.isModalVisible,
  errors: state.cities.errors,
  city: state.cities.city,
});

export default connect(mapStateToProps)(withRouter(City));
