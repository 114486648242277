// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  PageHeader,
  Pagination,
  Button,
  Badge,
  Input,
  Select
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;
const Option = Select.Option;

class adminsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      filterActiveVisible: false
    };
  }

  onSelectChange = selectedRowKeys => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // filters
  onChangeFilterActive = value => {
    this.setState({ filterActiveVisible: false }, () => {
      this.props.onChangeFilterActive(value);
    });
  };

  onChange = (pagination, filters, sorter) => {
    console.log(sorter);
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const {
      loading,
      admins,
      meta,
      fetchParams,
      onChangePage,
      onChangeLimit,
      onSearch
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "#",
        dataIndex: "is_active",
        render: (is_active, record) => (
          <span>
            <Badge status={is_active ? "success" : "error"} />
          </span>
        ),
        key: "is_active",
        width: "5%",
        align: "center",
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: fetchParams.status ? "#1890ff" : undefined
            }}
          />
        ),
        filterDropdown: (
          <div className="custom-filter-dropdown">
            <Row gutter={10}>
              <Col span={24}>
                <Select
                  style={{ width: "100%" }}
                  value={fetchParams.is_active}
                  placeholder="Статус"
                  optionFilterProp="children"
                  filterOption={false}
                  onChange={this.onChangeFilterActive}
                >
                  <Option value={true}>активен</Option>
                  <Option value={false}>неактивен</Option>
                </Select>
              </Col>
            </Row>
            <Row gutter={10} style={{ marginTop: "10px" }}>
              <Col span={24} style={{ textAlign: "right" }}>
                <Button type="primary" onClick={this.onResetFilterActive}>
                  Сбросить
                </Button>
              </Col>
            </Row>
          </div>
        ),
        filterIcon: filtered => (
          <FilterOutlined
            style={{
              color: fetchParams.is_active ? "#1890ff" : undefined
            }}
          />
        ),
        filterDropdownVisible: this.state.filterActiveVisible,
        onFilterDropdownVisibleChange: visible =>
          this.setState({ filterActiveVisible: visible })
      },
      {
        title: "ФИО",
        dataIndex: "name",
        sorter: true,
        sortDirections: ["ascend", "descend"],
        render: (name, record) => (
          <Link to={`/admins/${record.id}/edit`}>{record.name}</Link>
        ),
        key: "name",
        width: "60%"
      },
      {
        title: "Email / Телефон",
        dataIndex: "email",
        key: "email",
        render: (email, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{email}</span>
            <span>{record.phone}</span>
          </div>
        ),
        width: "25%"
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: created_at => moment(created_at).format("L"),
        key: "created_at",
        width: "15%",
        align: "center"
      }
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/admins/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Администраторы`}
        extra={[
          <Search
            allowClear
            key="3"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
            allowClear
          />,
          mainButton
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={admins}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{ paddingTop: "15px", paddingBottom: "15px", textAlign: "right" }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={total => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

adminsList.propTypes = {
  admins: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func
};

export default adminsList;
