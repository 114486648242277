import api from "../api";

const Actions = {};

Actions.fetchSupportMessageTypes = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPES_REQUEST" });
    let params = getState().supportMessageTypes.fetchParams;

    const data = {
      search: params.search,
      sort: params.sort,
      direction: params.direction,
      page: params.page,
      limit: params.limit,
    };

    api
      .fetch("/support_message_types", data)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPES_FAILURE", response });
      });
  };
};

Actions.fetchSupportMessageType = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPE_REQUEST" });
    api
      .fetch(`/support_message_types/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPE", response });
      })
      .catch((error) => {
        api.handleError(error, "Тип сообщения");
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPE_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_SUPPORT_MESSAGE_TYPE_REQUEST" });
    const message = getState().supportMessageTypes.supportMessageType;
    const data = {
      support_message_type: {
        name: message.name,
        kind: message.kind,
        is_common_support: message.is_common_support,
        email: message.email,
      },
    };

    return api
      .patch(`/support_message_types/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_SUPPORT_MESSAGE_TYPE", response });
        api.openNotification("success", "Тип сообщения", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Тип сообщения", error);
          });
        }
        dispatch({ type: "PATCH_SUPPORT_MESSAGE_TYPE_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_SUPPORT_MESSAGE_TYPE_REQUEST" });

    const message = getState().supportMessageTypes.supportMessageType;
    const data = {
      support_message_type: {
        name: message.name,
        kind: message.kind,
        is_common_support: message.is_common_support,
        email: message.email,
      },
    };

    return api
      .post(`/support_message_types`, data)
      .then((response) => {
        dispatch({ type: "POST_SUPPORT_MESSAGE_TYPE_SUCCEED", response });
        api.openNotification("success", "Тип сообщения", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Тип сообщения", error);
          });
        }
        dispatch({ type: "POST_SUPPORT_MESSAGE_TYPE_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_SUPPORT_MESSAGE_REQUEST" });
    return api
      .delete(`/support_message_types/${id}`)
      .then((response) => {
        api.openNotification("success", "Тип сообщения", "Успешно удален!");
        dispatch({ type: "DELETE_SUPPORT_MESSAGE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Тип сообщения", error);
          });
        }
        dispatch({
          type: "DELETE_SUPPORT_MESSAGE_FAILURE",
        });
      });
  };
};

// assignees
Actions.fetchAssignees = (id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPE_ASSIGNEES_REQUEST" });
    api
      .fetch(`/support_message_types/${id}/support_message_type_assignees`)
      .then((response) => {
        dispatch({ type: "FETCH_SUPPORT_MESSAGE_TYPE_ASSIGNEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({
          type: "FETCH_SUPPORT_MESSAGE_TYPE_ASSIGNEES_FAILURE",
          response,
        });
      });
  };
};

Actions.onCreateAssignee = (id, assignee, type) => {
  return (dispatch) => {
    dispatch({ type: "POST_SUPPORT_MESSAGE_TYPE_ASSIGNEE_REQUEST" });
    return api
      .post(`/support_message_types/${id}/support_message_type_assignees`, {
        support_message_type_assignee: {
          assignee_type: type,
          admin_id: assignee.id,
        },
      })
      .then((response) => {
        dispatch({ type: "POST_SUPPORT_MESSAGE_TYPE_ASSIGNEE", response });
        api.openNotification("success", "Ответственный", "Успешно добавлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({
          type: "POST_SUPPORT_MESSAGE_TYPE_ASSIGNEE_FAILURE",
          response,
        });
      });
  };
};

Actions.onDeleteAssignee = (support_message_type_id, id) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_ASSIGNEE_DELETE_REQUEST",
    });
    return api
      .delete(
        `/support_message_types/${support_message_type_id}/support_message_type_assignees/${id}`
      )
      .then((response) => {
        api.openNotification("success", "Ответственный", "Успешно удален!");
        dispatch({
          type: "SUPPORT_MESSAGE_TYPE_ASSIGNEE_DELETE_SUCCEED",
          response,
        });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Ответственный", error);
          });
        }
        dispatch({ type: "SUPPORT_MESSAGE_TYPE_ASSIGNEE_DELETE_FAILED" });
      });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeKind = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_CHANGE_KIND",
      value: value,
    });
  };
};

Actions.onChangeIsCommonSupport = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_CHANGE_IS_COMMON_SUPPORT",
      value: value,
    });
  };
};

Actions.onChangeEmail = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_CHANGE_EMAIL",
      value: value,
    });
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_TYPE_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_SUPPORT_MESSAGE_TYPE_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

// filters
Actions.onSetFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_SET_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_CLEAR_FILTERS",
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterKinds = (values) => {
  return (dispatch) => {
    dispatch({
      type: "SUPPORT_MESSAGE_TYPE_FILTER_KINDS",
      values: values,
    });
    return Promise.resolve();
  };
};

export default Actions;
