import update from "immutability-helper";

const initialState = {
  tariffPlans: [],
  tariffPlan: { services: {} },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_TARIFF_PLANS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TARIFF_PLANS":
      return {
        ...state,
        tariffPlans: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_TARIFF_PLANS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_TARIFF_PLAN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_TARIFF_PLAN":
      return {
        ...state,
        tariffPlan: action.response.data,
        isLoading: false,
      };

    case "FETCH_TARIFF_PLAN_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };
    case "PATCH_TARIFF_PLAN_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_TARIFF_PLAN":
      return {
        ...state,
        tariffPlan: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_TARIFF_PLAN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_TARIFF_PLAN_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_TARIFF_PLAN":
      return {
        ...state,
        errors: false,
        tariffPlan: action.response.data,
        isLoading: false,
      };

    case "POST_TARIFF_PLAN_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_TARIFF_PLAN_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_TARIFF_PLAN":
      return {
        ...state,
        tariffPlans: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_TARIFF_PLAN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "TARIFF_PLAN_NEW":
      return {
        ...state,
        errors: false,
        tariffPlan: {
          is_active: true,
          services: {
            tickets: true,
            entity_tasks: true,
            order_tasks: false,
            orders: true,
            tasks: true,
            projects: true,
            payrolls: false,
          },
        },
        isLoading: false,
      };

    case "REQUEST_TARIFF_PLAN_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_TARIFF_PLAN_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_TARIFF_PLAN_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_TARIFF_PLANS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } },
      });

    case "TARIFF_PLANS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "TARIFF_PLAN_CHANGE_ACTIVE":
      return update(state, {
        tariffPlan: { is_active: { $set: action.value } },
      });

    case "TARIFF_PLAN_CHANGE_NAME":
      return update(state, {
        tariffPlan: { name: { $set: action.value } },
      });

    case "TARIFF_PLAN_CHANGE_DESCRIPTION":
      return update(state, {
        tariffPlan: { description: { $set: action.value } },
      });

    case "TARIFF_PLAN_CHANGE_PRICE":
      return update(state, {
        tariffPlan: { price: { $set: action.value } },
      });

    case "TARIFF_PLAN_CHANGE_PRICE_PER_USER":
      return update(state, {
        tariffPlan: { price_per_user: { $set: action.value } },
      });

    case "TARIFF_PLAN_CHANGE_USERS_QTY":
      return update(state, {
        tariffPlan: { users_qty: { $set: action.value } },
      });

    // services
    case "TARIFF_PLAN_CHANGE_SERVICES_TICKETS":
      return update(state, {
        tariffPlan: {
          services: { tickets: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_TICKET_REPLACES":
      return update(state, {
        tariffPlan: {
          services: { ticket_replaces: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_ENTITY_TASKS":
      return update(state, {
        tariffPlan: {
          services: { entity_tasks: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_ORDER_TASKS":
      return update(state, {
        tariffPlan: {
          services: { order_tasks: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_ORDERS":
      return update(state, {
        tariffPlan: {
          services: { orders: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_TASKS":
      return update(state, {
        tariffPlan: {
          services: { tasks: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_PROJECTS":
      return update(state, {
        tariffPlan: {
          services: { projects: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_PAYROLLS":
      return update(state, {
        tariffPlan: {
          services: { payrolls: { $set: action.value } },
        },
      });

    case "TARIFF_PLAN_CHANGE_SERVICES_AMOUNT":
      return update(state, {
        tariffPlan: {
          services: { amount: { $set: action.value } },
        },
      });

    default:
      return state;
  }
}
