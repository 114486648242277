import api from "../api";

const Actions = {};

Actions.fetchFaqs = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_FAQS_REQUEST" });
    const params = getState().faqs.fetchParams;
    api
      .fetch("/faqs", params)
      .then(response => {
        dispatch({ type: "FETCH_FAQS", response });
      })
      .catch(response => {
        console.log(response.error);
        dispatch({ type: "FETCH_FAQS_FAILURE", response });
      });
  };
};

Actions.fetchFaq = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_FAQ_REQUEST" });
    api
      .fetch(`/faqs/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_FAQ", response });
      })
      .catch(error => {
        api.handleError(error, "Пост");
        dispatch({ type: "FETCH_FAQ_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch, getState) => {
    dispatch({
      type: "FAQ_NEW"
    });
  };
};

Actions.onUpdate = id => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_FAQ_REQUEST" });
    const faq = getState().faqs.faq;
    let tags = []
    faq.tags.map((tag) => {
      return (
        tags.push(tag.id)
      );
    });
    const data = {
        faq: {
          published_at: faq.published_at,
          contex: faq.contex,
          subcontext: faq.subcontext,
          application_type: faq.application_type,
          title: faq.title,
          body: faq.body,
          tag_ids: tags
        }
      };

    api
      .patch(`/faqs/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_FAQ_SUCCEED", response });
        api.openNotification("success", "Статья", "Успешно обновлена!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Статья", error);
          });
        }
        dispatch({ type: "PATCH_FAQ_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_FAQ_REQUEST" });
    const faq = getState().faqs.faq;
    let tags = []
    faq.tags.map((tag) => {
      return (
        tags.push(tag.id)
      );
    });

    const data = {
      faq: {
        published_at: faq.published_at,
        contex: faq.contex,
        subcontext: faq.subcontext,
        application_type: faq.application_type,
        title: faq.title,
        body: faq.body,
        tag_ids: tags
      }
    };
    return api
      .post(`/faqs`, data)
      .then(response => {
        dispatch({ type: "POST_FAQ_SUCCEED", response });
        api.openNotification("success", "Статья", "Успешно создана!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Статья", error);
          });
        }
        dispatch({ type: "POST_FAQ_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_FAQ_REQUEST" });
    return api
      .delete(`/faqs/${id}`)
      .then(response => {
        api.openNotification("success", "Пост", "Успешно удалено!");
        dispatch({ type: "DELETE_FAQ", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пост", error);
          });
        }
        dispatch({
          type: "DELETE_FAQ_FAILURE"
        });
      });
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_FAQ_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_FAQ_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "POSTS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeTitle = value => {
    return dispatch => {
      dispatch({
        type: "FAQ_CHANGE_TITLE",
        value: value
      });
    };
  };
  
  Actions.onChangeBody = value => {
    return dispatch => {
      dispatch({
        type: "FAQ_CHANGE_BODY",
        value: value
      });
    };
  };

  Actions.onChangeTags = values => {
    return dispatch => {
      dispatch({
        type: "FAQ_CHANGE_TAGS",
        values: values
      });
    };
  };

Actions.onChangePublished = value => {
  return dispatch => {
    dispatch({
      type: "FAQ_CHANGE_PUBLISHED",
      value: value
    });
  };
};

Actions.onChangeApplicationType = value => {
  return dispatch => {
    dispatch({
      type: "FAQ_CHANGE_APPLICATION_TYPE",
      value: value
    });
  };
};


Actions.onChangeContex = value => {
  return dispatch => {
    dispatch({
      type: "FAQ_CHANGE_CONTEX",
      value: value
    });
  };
};

Actions.onChangeSubcontext = value => {
    return dispatch => {
      dispatch({
        type: "FAQ_CHANGE_SUBCONTEX",
        value: value
      });
    };
  };


export default Actions;
