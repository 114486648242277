// @flow
import React, { Component } from "react";
import { Row, Col, Checkbox } from "antd";

class Services extends Component {
  render() {
    const {
      services,
      onChangeTickets,
      onChangeTicketReplaces,
      onChangeEntityTasks,
      onChangeOrderTasks,
      onChangeOrders,
      onChangeTasks,
      onChangeProjects,
      onChangePayrolls,
      onChangeAmount
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.tickets} onChange={onChangeTickets}>
              заявки
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.ticket_replaces} onChange={onChangeTicketReplaces}>
              заявки с заменой
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={services.entity_tasks}
              onChange={onChangeEntityTasks}
            >
              регламент
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox
              checked={services.order_tasks}
              onChange={onChangeOrderTasks}
            >
              задачи по заказам
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.orders} onChange={onChangeOrders}>
              заказы
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.tasks} onChange={onChangeTasks}>
              задачи
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.projects} onChange={onChangeProjects}>
              проекты
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.payrolls} onChange={onChangePayrolls}>
              начисления
            </Checkbox>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Checkbox checked={services.amount} onChange={onChangeAmount}>
              сумма
            </Checkbox>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Services.propTypes = {};

export default Services;
