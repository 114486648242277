import api from "../api";

const Actions = {};

Actions.fetchAdmins = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ADMINS_REQUEST" });
    const params = getState().admins.fetchParams;
    api
      .fetch("/admins", params)
      .then(response => {
        dispatch({ type: "FETCH_ADMINS", response });
      })
      .catch(error => {
        if (error.response.status == 500) {
          error.response.data.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "FETCH_ADMINS_FAILURE", error });
      });
  };
};

Actions.fetchAdmin = (id = null) => {
  return dispatch => {
    dispatch({ type: "FETCH_ADMIN_REQUEST" });
    api
      .fetch(`/admins/${id}`)
      .then(response => {
        dispatch({ type: "FETCH_ADMIN", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "FETCH_ADMIN_FAILURE", response });
      });
  };
};

Actions.onNew = () => {
  return dispatch => {
    dispatch({
      type: "ADMIN_NEW"
    });
  };
};

Actions.onUpdate = (id, avatar) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ADMIN_REQUEST" });
    const admin = getState().admins.admin;
    const data = {
      admin: {
        is_active: admin.is_active,
        role: admin.role,
        title: admin.title,
        email: admin.email,
        phone: admin.phone,
        name: admin.name,
        avatar: avatar ? avatar.attachment : null
      }
    };
    api
      .patch(`/admins/${id}`, data)
      .then(response => {
        dispatch({ type: "PATCH_ADMIN", response });
        api.openNotification("success", "Пользователь", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            return api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "PATCH_ADMIN_FAILURE", response });
      });
  };
};

Actions.onUpdatePassword = () => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_PASSWORD_ADMIN_REQUEST" });
    const admin = getState().admins.admin;
    const data = {
      admin: {
        password: admin.password,
        password_confirmation: admin.password_confirmation
      }
    };
    api
      .patch(`/admins/${admin.id}/update_password`, data)
      .then(response => {
        dispatch({ type: "PATCH_PASSWORD_ADMIN", response });
        api.openNotification("success", "Пароль", "Успешно обновлен!");
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пароль", error);
          });
        }
        dispatch({ type: "PATCH_PASSWORD_ADMIN_FAILURE", response });
      });
  };
};

Actions.onCreate = (avatar) => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ADMIN_REQUEST" });
    const admin = getState().admins.admin;
    const data = {
      admin: {
        is_active: admin.is_active,
        role: admin.role,
        title: admin.title,
        email: admin.email,
        phone: admin.phone,
        name: admin.name,
        password: admin.password,
        password_confirmation: admin.password_confirmation,
        avatar: avatar ? avatar.attachment : null
      }
    };
    return api
      .post(`/admins`, data)
      .then(response => {
        dispatch({ type: "POST_ADMIN", response });
        api.openNotification(
          "success",
          "Пользователь системы",
          "Успешно создан!"
        );
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь системы", error);
          });
        }
        dispatch({ type: "POST_ADMIN_FAILURE", response });
      });
  };
};

Actions.onDelete = id => {
  return dispatch => {
    dispatch({ type: "DELETE_ADMIN_REQUEST" });
    return api
      .delete(`/admins/${id}`)
      .then(response => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_ADMIN", response });
      })
      .catch(response => {
        if (response.errors) {
          response.errors.map(error => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_ADMIN_FAILURE"
        });
      });
  };
};

Actions.onUploadAvatar = (id, file) => {
  return dispatch => {
    dispatch({
      type: "ADMIN_AVATAR_UPLOAD"
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/admins/${id}/avatar`, formData)
      .then(response => {
        api.openNotification(
          "success",
          "Пользователь",
          "Аватар успешно загружен!"
        );
        dispatch({ type: "ADMIN_AVATAR_UPLOAD_SUCCEED", response });
      })
      .catch(response => {
        dispatch({
          type: "ADMIN_AVATAR_UPLOAD_FAILED"
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" }
        });
      });
  };
};

Actions.onSearch = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ADMIN_SEARCH",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ADMIN_CHANGE_PAGE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ADMIN_CHANGE_LIMIT",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onChangeFilterActive = value => {
  return dispatch => {
    dispatch({
      type: "REQUEST_ADMINS_FILTER_ACTIVE",
      value: value
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return dispatch => {
    dispatch({
      type: "ADMINS_SORT_BY",
      field: field,
      direction: direction
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_ACTIVE",
      value: value
    });
  };
};


Actions.onChangeRole = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_ROLE",
      value: value
    });
  };
};

Actions.onChangeName = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_NAME",
      value: value
    });
  };
};

Actions.onChangeEmail = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_EMAIL",
      value: value
    });
  };
};

Actions.onChangeTitle = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_TITLE",
      value: value
    });
  };
};

Actions.onChangePhone = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_PHONE",
      value: value
    });
  };
};

Actions.onChangePassword = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_PASSWORD",
      value: value
    });
  };
};

Actions.onChangePasswordConfirmation = value => {
  return dispatch => {
    dispatch({
      type: "ADMIN_CHANGE_PASSWORD_CONFIRMATION",
      value: value
    });
  };
};


export default Actions;
