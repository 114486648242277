// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import update from "immutability-helper";
import Actions from "../../../actions/admins";
import LoadingSpin from "../../../components/LoadingSpin";
import Form from "./Form";

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
      tempFile: null,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchAdmin(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    const { tempFile } = this.state;
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id, tempFile ? tempFile : null));
    } else {
      this.props.dispatch(Actions.onCreate(tempFile ? tempFile : null)).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/admins/${this.props.admin.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/admins`);
  };

  onChangeActive = (e) => {
    this.props.dispatch(Actions.onChangeActive(e.target.checked));
  };

  onChangeRole = (value) => {
    this.props.dispatch(Actions.onChangeRole(value));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeDepartment = (value) => {
    this.props.dispatch(Actions.onChangeDepartment(value));
  };

  onChangeEmail = (e) => {
    this.props.dispatch(Actions.onChangeEmail(e.target.value));
  };

  onChangeTitle = (e) => {
    this.props.dispatch(Actions.onChangeTitle(e.target.value));
  };

  onChangePhone = (e) => {
    this.props.dispatch(Actions.onChangePhone(e.target.value));
  };

  onUploadAvatar = (file) => {
    this.props.dispatch(Actions.onUploadAvatar(this.props.admin.id, file));
  };

  onTempFileUpload = (avatar) => {
    this.setState({
      tempFile: update(this.state.tempFile, {
        $set: avatar,
      }),
    });
  };

  // change password
  onUpdatePassword = () => {
    this.props.dispatch(Actions.onUpdatePassword());
  };

  onChangePassword = (e) => {
    this.props.dispatch(Actions.onChangePassword(e.target.value));
  };

  onChangePasswordConfirmation = (e) => {
    this.props.dispatch(Actions.onChangePasswordConfirmation(e.target.value));
  };

  render() {
    const { tempFile } = this.state;
    const { isLoading, isUploadingAvatar, errors, admin } = this.props;
    return isLoading || errors ? (
      <LoadingSpin />
    ) : (
      <Form
        isNew={this.state.isNew}
        admin={admin}
        onSave={this.onSave}
        onClose={this.onClose}
        isUploadingAvatar={isUploadingAvatar}
        onUploadAvatar={this.onUploadAvatar}
        onChangeActive={this.onChangeActive}
        onChangeName={this.onChangeName}
        onChangeEmail={this.onChangeEmail}
        onChangeTitle={this.onChangeTitle}
        onChangePhone={this.onChangePhone}
        onUpdatePassword={this.onUpdatePassword}
        onChangePassword={this.onChangePassword}
        onChangePasswordConfirmation={this.onChangePasswordConfirmation}
        // upload
        tempFile={tempFile}
        onTempFileUpload={this.onTempFileUpload}
      />
    );
  }
}

Admin.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  admin: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.admins.isLoading,
  isUploadingAvatar: state.admins.isUploadingAvatar,
  errors: state.admins.errors,
  admin: state.admins.admin,
});

export default connect(mapStateToProps)(withRouter(Admin));
