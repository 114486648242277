import update from "immutability-helper";
import moment from "moment";
import "moment/locale/ru";

const initialState = {
  faqs: [],
  faq: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_FAQS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_FAQS":
      return {
        ...state,
        faqs: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_FAQ_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_FAQ":
      return {
        ...state,
        faq: action.response.data,
        isLoading: false,
      };

    case "PATCH_FAQ_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "PATCH_FAQ_SUCCEED":
      return {
        ...state,
        faq: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_FAQ_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_FAQ_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_FAQ_SUCCEED":
      return {
        ...state,
        errors: false,
        faq: action.response.data,
        isLoading: false,
      };

    case "POST_FAQ_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_FAQ_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_FAQ":
      return {
        ...state,
        isLoading: false,
        errors: false,
      };
    case "DELETE_FAQ_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "FAQ_NEW":
      return {
        ...state,
        errors: false,
        faq: { published_at: null, tags: [] },
        isLoading: false,
      };

    case "FAQ_CHANGE_TITLE":
      return update(state, {
        faq: { title: { $set: action.value } },
      });

    case "FAQ_CHANGE_BODY":
      return update(state, {
        faq: { body: { $set: action.value } },
      });

    case "FAQ_CHANGE_TAGS":
      return update(state, {
        faq: { tags: { $set: action.values } },
      });

    case "FAQ_CHANGE_PUBLISHED":
      return update(state, {
        faq: { published_at: { $set: action.value ? moment() : null } },
      });

    case "FAQ_CHANGE_APPLICATION_TYPE":
      return update(state, {
        faq: { application_type: { $set: action.value } },
      });

    case "FAQ_CHANGE_CONTEX":
      return update(state, {
        faq: { contex: { $set: action.value } },
      });

    case "FAQ_CHANGE_SUBCONTEX":
      return update(state, {
        faq: { subcontext: { $set: action.value } },
      });

    default:
      return state;
  }
}
