// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Layout, Menu, Dropdown, Avatar, Tooltip } from "antd";
import {
  LogoutOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from "@ant-design/icons";

const { Header } = Layout;

class MainHeader extends Component {
  static contextTypes = {
    router: PropTypes.object,
  };

  constructor(props) {
    super(props);
    this.state = {
      isVisible: false,
    };
  }

  render() {
    const { isVisible } = this.state;
    const {
      isSidebarCollapsed,
      onToggleSideBar,
      currentAdmin,
      onLogout,
    } = this.props;
    const menu = (
      <Menu className={"menu"} selectedKeys={[]}>
        <Menu.Item key="logout" onClick={onLogout}>
          <LogoutOutlined />
          Выход
        </Menu.Item>
      </Menu>
    );

    return (
      <div>
        <Header
          className="main-header"
          style={{ position: "fixed", zIndex: 1000, width: "100%" }}
        >
          {/* <Tooltip
            placement="bottom"
            title={isSidebarCollapsed ? "Развернуть меню" : "Свернуть меню"}
          >
            {React.createElement(
              isSidebarCollapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger",
                onClick: onToggleSideBar,
              }
            )}
          </Tooltip> */}
          <div
            className={"right"}
            style={{
              paddingRight: `${isSidebarCollapsed ? "100px" : "220px"}`,
            }}
          >
            {currentAdmin ? (
              <Dropdown overlay={menu}>
                <span className={`${"action"} ${"account"}`}>
                  <Avatar
                    size="default"
                    className={"avatar"}
                    src={currentAdmin.avatar}
                  />
                  <span className={"name"}>{currentAdmin.name}</span>
                </span>
              </Dropdown>
            ) : null}
          </div>
        </Header>
      </div>
    );
  }
}

MainHeader.propTypes = {
  isLoading: PropTypes.bool,
  isSidebarCollapsed: PropTypes.bool,
  onToggleSideBar: PropTypes.func,
  onLogout: PropTypes.func,
  currentAdmin: PropTypes.object,
};

export default withRouter(MainHeader);
