// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, InputNumber, Col, Row } from "antd";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  constructor(props, context) {
    super(props, context);
  }
  render() {
    const { supportMessage, onChangeSubject, onChangeDescription } = this.props;

    return (
      <div>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={"Тема"}
              name="subject"
              initialValue={supportMessage.subject}
              onChange={onChangeSubject}
            >
              <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={"Описание"}
              name="description"
              initialValue={supportMessage.description}
              onChange={onChangeDescription}
            >
              <TextArea autoSize={{ minRows: 1, maxRows: 6 }} />
            </Form.Item>
          </Col>
        </Row>
      </div>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
};

export default General;
