// @flow
import React, { Component } from "react";
import { Row, Col, Form, Input, InputNumber, Checkbox } from "antd";

class Header extends Component {
  render() {
    const {
      isNew,
      isActive,
      name,
      description,
      price,
      pricePerUser,
      usersQty,
      onChangeActive,
      onChangeName,
      onChangeDescription,
      onChangePrice,
      onChangePricePerUser,
      onChangeUsersQty
    } = this.props;


    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={18}>
            <Form.Item
              label={"Наименование"}
              name="name"
              initialValue={name}
              onChange={onChangeName}
              rules={[{ required: true, message: "Введите наименование!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label={"Активен"}
              name="isActive"
              initialValue={isActive}
              valuePropName="checked"
              onChange={onChangeActive}
            >
              <Checkbox />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              label={"Описание"}
              name="description"
              initialValue={description}
              onChange={onChangeDescription}
              rules={[{ required: true, message: "Введите описание!" }]}
            >
              <Input />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={"Стоимость тарифного плана"}
              name="price"
              initialValue={price}
              onChange={onChangePrice}
              rules={[{ required: true, message: "Введите стоимость!" }]}
            >
              <Input style={{width: "100%"}} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={"Стоимость (за пользователя)"}
              name="pricePerUser"
              initialValue={pricePerUser}
              onChange={onChangePricePerUser}
              rules={[{ required: true, message: "Введите стоимость!" }]}
            >
              <Input style={{width: "100%"}} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              label={"Кол-во пользователей"}
              name="usersQty"
              initialValue={usersQty}
              onChange={onChangeUsersQty}
              rules={[{ required: true, message: "Введите кол-во пользователей!" }]}
            >
              <Input style={{width: "100%"}} />
            </Form.Item>
          </Col>

        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
