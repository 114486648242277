import React from "react";
import UserInfo from "../UserInfo";


export default function AssigneesCell({ assignees = [] }) {
  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      {assignees.map((item) => {
        return item.admin ? (
          <span key={`admin-${item.admin.id}`}>
            <UserInfo title={item.admin.name} adminId={item.admin.id} />
          </span>
        ) :  null;
      })}
    </div>
  );
}
