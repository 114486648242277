// @flow
import React, { Component } from "react";
import "./login.css";
import { Form, Input, Button } from "antd";
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import "./login.css";

class LoginForm extends Component {
  onFinish = (values) => {
    // console.log(values);
    this.props.onSubmit(values);
  };

  render() {
    const { submitting } = this.props;

    return (
      <div className="loginpagewrap">
        <div className="loginWrap">
          <Form className="login-form" onFinish={this.onFinish}>
            <Form.Item
              name="email"
              rules={[
                { required: true, message: "Пожалуйста, введите логин!" },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="пользователь" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: "Пожалуйста, введите пароль!" },
              ]}
            >
              <Input
                prefix={<LockOutlined />}
                type="password"
                placeholder="пароль"
              />
            </Form.Item>

            <Form.Item>
              <Button
                typeof="submit"
                disabled={submitting}
                htmlType="submit"
                className="login-form-button"
              >
                {submitting ? "Вход..." : "Войти"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  }
}

export default LoginForm;
