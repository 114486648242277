// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/organisations";
import LoadingSpin from "../../../components/LoadingSpin";

const Form = lazy(() => import("./Form"));
const renderLoader = () => <LoadingSpin />;

class Organisation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchOrganisation(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/organisations/${this.props.organisation.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/organisations`);
  };

  // general
  onChangeActive = (e) => {
    this.props.dispatch(Actions.onChangeActive(e.target.checked));
  };

  onUploadLogo = (file) => {
    this.props.dispatch(Actions.onUploadLogo(this.props.organisation.id, file));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  // user
  onDeleteUsers = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDeleteUsers(id)).then(() => {
        this.props.dispatch(Actions.fetchUsers(this.props.match.params.id));
      });
    });
  };

  onSaveUser = (values) => {
    if (values.id) {
      this.props
        .dispatch(Actions.onUpdateUser(this.props.match.params.id, values))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(Actions.fetchUsers(this.props.match.params.id));
          }
        });
    } else {
      this.props
        .dispatch(Actions.onCreateUser(this.props.match.params.id, values))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(Actions.fetchUsers(this.props.match.params.id));
          }
        });
    }
  };

  // tariff_plan
  onChangeTariffPlan = (value) => {
    this.props.dispatch(Actions.onChangeTariffPlan(value));
  };

  render() {
    const {
      isLoading,
      isUploadingLogo,
      isUsersLoading,
      errors,
      organisation,
      statistic,
    } = this.props;

    return (
      <Suspense fallback={renderLoader()}>
        <Form
          isNew={this.state.isNew}
          isLoading={isLoading}
          errors={errors}
          organisation={organisation}
          statistic={statistic}
          onSave={this.onSave}
          onClose={this.onClose}
          // general
          onChangeActive={this.onChangeActive}
          isUploadingLogo={isUploadingLogo}
          onUploadLogo={this.onUploadLogo}
          onChangeName={this.onChangeName}
          // users
          isUsersLoading={isUsersLoading}
          onDeleteUsers={this.onDeleteUsers}
          onSaveUser={this.onSaveUser}
          // tariff_plan
          onChangeTariffPlan={this.onChangeTariffPlan}
        />
      </Suspense>
    );
  }
}

Organisation.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  organisation: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.organisations.isLoading,
  isUploadingLogo: state.organisations.isUploadingLogo,
  errors: state.organisations.errors,
  organisation: state.organisations.organisation,
  statistic: state.organisations.statistic,
  // users
  isUsersLoading: state.organisations.isUsersLoading,
});

export default connect(mapStateToProps)(withRouter(Organisation));
