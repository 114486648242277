// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, InputNumber, Tooltip } from "antd";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  render() {
    const {
      city,
      onChangeRegion,
      onChangeArea,
      onChangeName,
      onChangeCityType,
      onChangeFullName,
      onChangeKladr,
      onChangeFias,
      onChangeOkato,
      onChangeLat,
      onChangeLon,
    } = this.props;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label={"Наименование"}
            name="name"
            initialValue={city.name}
            onChange={onChangeName}
            rules={[{ required: true, message: "Введите наименование!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            label={"Полное наименование"}
            name="full_name"
            initialValue={city.full_name}
            onChange={onChangeFullName}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={"Регион"}
            name="region"
            initialValue={city.region}
            onChange={onChangeRegion}
          >
            <SelectFetch
              showSearch
              placeholder="Регион"
              onChange={onChangeRegion}
              selected={city.region}
              url={"/regions/search/?"}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label={"Район"}
            name="region"
            initialValue={city.area}
            onChange={onChangeArea}
          >
            <SelectFetch
              showSearch
              placeholder="Район"
              onChange={onChangeArea}
              selected={city.area}
              url={
                city.region
                  ? `/areas/search/?region=${city.region.id}`
                  : `/areas/search/?`
              }
            />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Тип нас. пункта"}
            name="city_type"
            initialValue={city.city_type}
            onChange={onChangeCityType}
          >
            <Input />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            label={"КЛАДР"}
            name="kladr_id"
            initialValue={city.kladr_id}
            onChange={onChangeKladr}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"ФИАС"}
            name="fias_id"
            initialValue={city.fias_id}
            onChange={onChangeFias}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"ОКАТО"}
            name="okato"
            initialValue={city.okato}
            onChange={onChangeOkato}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Широта"}
            name="lat"
            initialValue={city.lat}
            onChange={onChangeLat}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Долгота"}
            name="lon"
            initialValue={city.lon}
            onChange={onChangeLon}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
