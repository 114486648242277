// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { List, Avatar, Col, Divider } from "antd";
import {
  FileImageOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FileUnknownOutlined
} from "@ant-design/icons";

function selectIcon(contentType) {
  switch (contentType) {
    case "application/pdf":
      return <FilePdfOutlined />;
    case "image/png":
      return <FileImageOutlined />;
    case "image/jpeg":
      return <FileImageOutlined />;
    case "application/msword":
      return <FileWordOutlined />;
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return <FileWordOutlined />;
    case "application/vnd.ms-excel":
      return <FileExcelOutlined />;
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return <FileExcelOutlined />;
    default:
      return <FileUnknownOutlined />;
  }
}

function selectColor(contentType) {
  switch (contentType) {
    case "application/pdf":
      return "red";
    case "image/png":
      return "brown";
    case "image/jpeg":
      return "brown";
    case "application/msword":
      return "cornflowerblue";
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
      return "cornflowerblue";
    case "application/vnd.ms-excel":
      return "green";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
      return "green";
    default:
      return "brown";
  }
}

class Documents extends Component {
  render() {
    const { attachments } = this.props;

    return (
      <React.Fragment>
        <Divider>Файлы </Divider>
        <Col span={24}>
          <List
            dataSource={attachments}
            renderItem={(item) => (
              <List.Item>
                <List.Item.Meta
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: "transparent",
                        color: selectColor(item.content_type),
                      }}
                      icon={selectIcon(item.content_type)}
                      size="large"
                    />
                  }
                  title={
                    <a href={item.url} download={item.name}>
                      {item.name}
                    </a>
                  }
                  description={item.file_size}
                />
              </List.Item>
            )}
          />
        </Col>
      </React.Fragment>
    );
  }
}

Documents.propTypes = {
  orderDocuments: PropTypes.arrayOf(PropTypes.object),
  total: PropTypes.number,
  discount: PropTypes.object,
  shippingCost: PropTypes.number,
  amountDiscountCode: PropTypes.number,
  onRemoveVariant: PropTypes.func,
};
export default Documents;
