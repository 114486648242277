import update from "immutability-helper";

const initialState = {
  admins: [],
  admin: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isUploadingAvatar: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_ADMINS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ADMINS":
      return {
        ...state,
        admins: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_ADMINS_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "FETCH_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ADMIN":
      return {
        ...state,
        admin: action.response.data,
        isLoading: false,
      };

    case "FETCH_ADMIN_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };
    case "PATCH_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_ADMIN":
      return {
        ...state,
        admin: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_ADMIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_ADMIN_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_ADMIN":
      return {
        ...state,
        errors: false,
        admin: action.response.data,
        isLoading: false,
      };

    case "POST_ADMIN_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_ADMIN_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_ADMIN":
      return {
        ...state,
        admins: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_ADMIN_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "ADMIN_NEW":
      return {
        ...state,
        errors: false,
        admin: {
          is_active: true
        },
        isLoading: false,
      };

    case "PATCH_PASSWORD_ADMIN_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "PATCH_PASSWORD_ADMIN":
      return {
        ...state,
        errors: false,
        isLoading: false,
      };
    case "PATCH_PASSWORD_ADMIN_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "REQUEST_ADMIN_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ADMIN_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_ADMIN_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_ADMINS_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } },
      });

    case "ADMINS_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "ADMIN_CHANGE_ACTIVE":
      return update(state, {
        admin: { is_active: { $set: action.value } },
      });

    case "ADMIN_CHANGE_START":
      return update(state, {
        admin: { start: { $set: action.value } },
      });

    case "ADMIN_CHANGE_FINISH":
      return update(state, {
        admin: { finish: { $set: action.value } },
      });

    case "ADMIN_CHANGE_ROLE":
      return update(state, {
        admin: { role: { $set: action.value } },
      });

    case "ADMIN_CHANGE_NAME":
      return update(state, {
        admin: { name: { $set: action.value } },
      });

    case "ADMIN_CHANGE_EMAIL":
      return update(state, {
        admin: { email: { $set: action.value } },
      });

    case "ADMIN_CHANGE_TITLE":
      return update(state, {
        admin: { title: { $set: action.value } },
      });

    case "ADMIN_CHANGE_PHONE":
      return update(state, {
        admin: { phone: { $set: action.value } },
      });

    case "ADMIN_CHANGE_PASSWORD":
      return update(state, {
        admin: { password: { $set: action.value } },
      });

    case "ADMIN_CHANGE_PASSWORD_CONFIRMATION":
      return update(state, {
        admin: { password_confirmation: { $set: action.value } },
      });

    // avatar
    case "ADMIN_AVATAR_UPLOAD":
      return update(state, {
        isUploadingAvatar: { $set: true },
      });

    case "ADMIN_AVATAR_UPLOAD_SUCCEED":
      return update(state, {
        admin: { avatar: { $set: action.response.data.avatar } },
        errors: { $set: false },
        isUploadingAvatar: { $set: false },
      });

    case "ADMIN_AVATAR_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingAvatar: { $set: false },
      });

    default:
      return state;
  }
}
