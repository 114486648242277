// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Button, Form, Card } from "antd";
import Header from "./Header";
import Services from "./Services";

class TariffPlanForm extends Component {
  onFinish = (values) => {
    this.props.onSave(values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    const {
      onClose,
      isNew,
      isLoading,
      tariffPlan,
      onChangeActive,
      onChangeName,
      onChangeDescription,
      onChangePrice,
      onChangePricePerUser,
      onChangeUsersQty,
      // services
      onChangeTickets,
      onChangeTicketReplaces,
      onChangeEntityTasks,
      onChangeOrderTasks,
      onChangeOrders,
      onChangeTasks,
      onChangeProjects,
      onChangePayrolls,
      onChangeAmount
    } = this.props;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Тарифный план #${
            isNew ? " новый" : `${tariffPlan.name}`
          }`}</h2>
        }
        extra={
          <div>
            <Button onClick={onClose}>Закрыть</Button>
            <Button
              onClick={this.onFinish}
              type="primary"
              ghost
              style={{ marginLeft: "8px" }}
            >
              Сохранить
            </Button>
          </div>
        }
      >
        <Form layout={"vertical"}>
          <Col xs={24} sm={24} md={24} lg={16} xl={16}>
            <Header
              isNew={isNew}
              isActive={tariffPlan.is_active}
              name={tariffPlan.name}
              description={tariffPlan.description}
              price={tariffPlan.price}
              pricePerUser={tariffPlan.price_per_user}
              usersQty={tariffPlan.users_qty}
              onChangeActive={onChangeActive}
              onChangeName={onChangeName}
              onChangeDescription={onChangeDescription}
              onChangePrice={onChangePrice}
              onChangePricePerUser={onChangePricePerUser}
              onChangeUsersQty={onChangeUsersQty}
            />
            <Services
              services={tariffPlan.services}
              onChangeTickets={onChangeTickets}
              onChangeTicketReplaces={onChangeTicketReplaces}
              onChangeEntityTasks={onChangeEntityTasks}
              onChangeOrderTasks={onChangeOrderTasks}
              onChangeOrders={onChangeOrders}
              onChangeTasks={onChangeTasks}
              onChangeProjects={onChangeProjects}
              onChangePayrolls={onChangePayrolls}
              onChangeAmount={onChangeAmount}
            />
          </Col>
        </Form>
      </Card>
    );
  }
}

TariffPlanForm.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  tariffPlan: PropTypes.object,
  onSave: PropTypes.func,
  isLoading: PropTypes.bool,
  router: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  form: PropTypes.object,
  onChangeName: PropTypes.func,
};

export default TariffPlanForm;
