// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/supportMessages";
import SupportMessagesList from "./List";

class SupportMessages extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() => {
      this.props.dispatch(Actions.fetchSupportMessages());
    });
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchSupportMessages());
      });
    });
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  // filters
  onClearFilters = () => {
    this.props
      .dispatch(Actions.onClearFilters())
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangeFilterStatuses = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterStatuses(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangeFilterTypes = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterTypes(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangeFilterUsers = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterUsers(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  onChangeFilterMembers = (value) => {
    this.props
      .dispatch(Actions.onChangeFilterMembers(value))
      .then(() => this.props.dispatch(Actions.fetchSupportMessages()));
  };

  render() {
    const { currentUser, isLoading, supportMessages, meta, fetchParams } =
      this.props;
    return (
      <SupportMessagesList
        currentUser={currentUser}
        loading={isLoading}
        supportMessages={supportMessages}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterStatuses={this.onChangeFilterStatuses}
        onChangeFilterTypes={this.onChangeFilterTypes}
        onChangeFilterUsers={this.onChangeFilterUsers}
        onChangeFilterMembers={this.onChangeFilterMembers}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
  isLoading: state.supportMessages.isLoading,
  supportMessages: state.supportMessages.supportMessages,
  meta: state.supportMessages.meta,
  fetchParams: state.supportMessages.fetchParams,
});

export default connect(mapStateToProps)(SupportMessages);
