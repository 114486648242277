import api from "../api";

const Actions = {};

Actions.fetchOrganisations = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_ORGANISATIONS_REQUEST" });
    const params = getState().organisations.fetchParams;
    api
      .fetch("/organisations", params)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATIONS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORGANISATIONS_FAILURE", response });
      });
  };
};

Actions.fetchOrganisation = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_REQUEST" });
    api
      .fetch(`/organisations/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION", response });
      })
      .catch((error) => {
        api.handleError(error, "Организация");
        dispatch({ type: "FETCH_ORGANISATION_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_ORGANISATION_REQUEST" });
    const organisation = getState().organisations.organisation;

    const data = {
      organisation: {
        is_active: organisation.is_active,
        name: organisation.name,
        tariff_plan_id: organisation.tariff_plan ? organisation.tariff_plan.id : null,
      },
    };
    api
      .patch(`/organisations/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_ORGANISATION", response });
        api.openNotification("success", "Организация", "Успешно обновлена!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Организация", error);
          });
        }
        dispatch({ type: "PATCH_ORGANISATION_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_ORGANISATION_REQUEST" });
    const organisation = getState().organisations.organisation;
    const data = {
      organisation: {
        is_active: organisation.is_active,
        name: organisation.name,
        tariff_plan_id: organisation.tariff_plan ? organisation.tariff_plan.id : null,
      },
    };
    return api
      .post(`/organisations`, data)
      .then((response) => {
        dispatch({ type: "POST_ORGANISATION", response });
        api.openNotification("success", "Организация", "Успешно создана!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Организация", error);
          });
        }
        dispatch({ type: "POST_ORGANISATION_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORGANISATION_REQUEST" });
    return api
      .delete(`/organisations/${id}`)
      .then((response) => {
        api.openNotification("success", "Организация", "Успешно удалена!");
        dispatch({ type: "DELETE_ORGANISATION", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Организация", error);
          });
        }
        dispatch({
          type: "DELETE_ORGANISATION_FAILURE",
        });
      });
  };
};

Actions.onUploadLogo = (organisationId, file) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_LOGO_UPLOAD",
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/organisations/${organisationId}/logo`, formData)
      .then((response) => {
        api.openNotification(
          "success",
          "Организация",
          "Лого успешно загружено!"
        );
        dispatch({ type: "ORGANISATION_LOGO_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "ORGANISATION_LOGO_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

// users
Actions.onDeleteUsers = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_ORGANISATION_USER_REQUEST" });
    return api
      .delete(`/users/${id}`)
      .then((response) => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_ORGANISATION_USER", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_ORGANISATION_USER_FAILURE",
        });
      });
  };
};

Actions.onCreateUser = (organisation_id, values) => {
  return (dispatch) => {
    dispatch({ type: "POST_ORGANISATION_USER_REQUEST" });
    const data = {
      user: {
        is_active: true,
        role: 1,
        title: values.title,
        email: values.email,
        phone: values.phone,
        name: values.name,
        organisation_id: organisation_id,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
    };
    return api
      .post(`/users`, data)
      .then((response) => {
        dispatch({ type: "POST_ORGANISATION_USER", response });
        api.openNotification("success", "Пользователь", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "POST_ORGANISATION_USER_FAILURE", response });
      });
  };
};

Actions.fetchUsers = (organisation_id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_ORGANISATION_USERS_REQUEST" });
    return api
      .fetch("/users", { organisation: organisation_id })
      .then((response) => {
        dispatch({ type: "FETCH_ORGANISATION_USERS", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_ORGANISATION_USERS_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORGANISATION_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORGANISATIONS_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_ORGANISATIONS_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATIONS_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "ORGANISATION_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeTariffPlan = (value) => {
  return (dispatch) => {
    dispatch({
      type: "ORGANISATION_CHANGE_TARIFF_PLAN",
      value: value,
    });
  };
};

export default Actions;
