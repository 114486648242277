import update from "immutability-helper";
import _ from "lodash/array";

const initialState = {
  organisations: [],
  organisation: { users: [] },
  statistic: {
    users: 0,
    members: 0,
    tickets: 0,
    orders: 0,
    projects: 0,
    tasks: 0,
  },
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    active: true,
    search: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
  isUsersLoading: false,
  isUploadingLogo: false,
};

export default function (state = initialState, action) {
  let idx = -1;
  switch (action.type) {
    case "FETCH_ORGANISATIONS_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORGANISATIONS":
      return {
        ...state,
        organisations: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_ORGANISATION_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_ORGANISATION":
      return {
        ...state,
        organisation: action.response.data,
        statistic: action.response.statistic,
        isLoading: false,
      };
    case "PATCH_ORGANISATION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "PATCH_ORGANISATION":
      return {
        ...state,
        organisation: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_ORGANISATION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_ORGANISATION_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };
    case "POST_ORGANISATION":
      return {
        ...state,
        errors: false,
        organisation: action.response.data,
        isLoading: false,
      };

    case "POST_ORGANISATION_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_ORGANISATION_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };
    case "DELETE_ORGANISATION":
      return {
        ...state,
        organisations: action.response.data,
        isLoading: false,
        errors: false,
      };
    case "DELETE_ORGANISATION_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "ORGANISATION_NEW":
      return {
        ...state,
        errors: false,
        organisation: {id: null, name: null, tariff_plan: null},
        users: [],
        isLoading: false,
      };

    case "REQUEST_ORGANISATION_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });
    // pagination
    case "REQUEST_ORGANISATIONS_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_ORGANISATIONS_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    // general
    case "ORGANISATION_CHANGE_ACTIVE":
      return update(state, {
        organisation: { is_active: { $set: action.value } },
      });

    case "ORGANISATION_CHANGE_NAME":
      return update(state, {
        organisation: { name: { $set: action.value } },
      });

    case "ORGANISATION_CHANGE_TARIFF_PLAN":
      return update(state, {
        organisation: { tariff_plan: { $set: action.value } },
      });

    // logo
    case "ORGANISATION_LOGO_UPLOAD":
      return update(state, {
        isUploadingLogo: { $set: true },
      });

    case "ORGANISATION_LOGO_UPLOAD_SUCCEED":
      return update(state, {
        organisation: { logo: { $set: action.response.data.logo } },
        errors: { $set: false },
        isUploadingLogo: { $set: false },
      });

    case "ORGANISATION_LOGO_UPLOAD_FAILED":
      return update(state, {
        errors: { $set: true },
        isUploadingLogo: { $set: false },
      });

    // user
    case "DELETE_ORGANISATION_USER_REQUEST":
      return {
        ...state,
        isUsersLoading: true,
        errors: false,
      };
    case "DELETE_ORGANISATION_USER":
      return update(state, {
        errors: { $set: false },
        isUsersLoading: { $set: false },
      });

    case "DELETE_ORGANISATION_USER_FAILURE":
      return {
        ...state,
        isUsersLoading: false,
        errors: true,
      };

    case "FETCH_ORGANISATION_USERS_REQUEST":
      return {
        ...state,
        isUsersLoading: true,
      };
    case "FETCH_ORGANISATION_USERS":
      return update(state, {
        organisation: { users: { $set: action.response.data } },
        errors: { $set: false },
        isUsersLoading: { $set: false },
      });
    case "FETCH_ORGANISATION_USERS_FAILURE":
      return {
        ...state,
        errors: true,
        isUsersLoading: false,
      };

    case "POST_ORGANISATION_USER_REQUEST":
      return {
        ...state,
        errors: false,
        isUsersLoading: false,
      };
    case "POST_ORGANISATION_USER":
      return {
        ...state,
        errors: false,
        isUsersLoading: false,
      };

    case "POST_ORGANISATION_USER_FAILURE":
      return {
        ...state,
        errors: true,
        isUsersLoading: false,
      };

    default:
      return state;
  }
}
