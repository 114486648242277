// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Select } from "antd";
import User from "./User";
import Documents from "./Documents";
import Assignees from "../../../components/Assignees";
import SelectFetch from "../../../components/SelectFetch";

const FormItem = Form.Item;
const Option = Select.Option;

class Statuses extends Component {
  render() {
    const {
      user,
      member,
      attachments,
      status,
      supportMessageType,
      onChangeStatus,
      onChangeSupportMessageType,
      // assignees
      isAssigneesLoading,
      onCreateAssignee,
      onDeleteAssignee,
      adminAssignees,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item
              label={"Статус"}
              name="statuses"
              initialValue={status}
            >
              <Select
                placeholder="Статус"
                optionFilterProp="children"
                filterOption={false}
                value={status}
                onChange={onChangeStatus}
              >
                <Option value={"pending"}>новое</Option>
                <Option value={"processing"}>в работе</Option>
                <Option value={"closed"}>закрыто</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Тип"}
              name="supportMessageType"
              initialValue={supportMessageType ? supportMessageType : null}
              onChange={onChangeSupportMessageType}
              rules={[{ required: true, message: "Введите тип!" }]}
            >
              <SelectFetch
                showSearch
                placeholder="Тип"
                onChange={onChangeSupportMessageType}
                selected={supportMessageType ? supportMessageType : null}
                url={"/support_message_types/search/?"}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <User user={user ? user : member} />
          </Col>
        </Row>
        <Row gutter={10}>
          <Col span={24}>
            <Assignees
              isReadOnly={false}
              isAssigneesLoading={isAssigneesLoading}
              assigneeType="admin"
              assignees={adminAssignees}
              onCreateAssignee={onCreateAssignee}
              onDeleteAssignee={onDeleteAssignee}
            />
          </Col>
        </Row>
        <Row gutter={10}>
        <Col span={24}>
          <Documents attachments={attachments} />
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  entity: PropTypes.object,
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
