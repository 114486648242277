import update from "immutability-helper";

const initialState = {
  cities: [],
  city: {},
  meta: {
    total_pages: 1,
    current_page: 1,
    total_count: 1,
  },
  fetchParams: {
    search: null,
    regions: null,
    areas: null,
    sort: null,
    direction: null,
    page: 1,
    limit: 15,
  },
  errors: false,
  isLoading: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case "FETCH_CITIES_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CITIES":
      return {
        ...state,
        cities: action.response.data,
        meta: action.response.meta,
        isLoading: false,
      };
    case "FETCH_CITY_REQUEST":
      return {
        ...state,
        isLoading: true,
      };
    case "FETCH_CITY":
      return {
        ...state,
        city: action.response.data,
        isLoading: false,
      };

    case "PATCH_CITY_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "PATCH_CITY":
      return {
        ...state,
        city: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "PATCH_CITY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "POST_CITY_REQUEST":
      return {
        ...state,
        errors: false,
        isLoading: true,
      };

    case "POST_CITY":
      return {
        ...state,
        errors: false,
        city: action.response.data,
        isLoading: false,
      };

    case "POST_CITY_FAILURE":
      return {
        ...state,
        errors: true,
        isLoading: false,
      };

    case "DELETE_CITY_REQUEST":
      return {
        ...state,
        isLoading: true,
        errors: false,
      };

    case "DELETE_CITY":
      return {
        ...state,
        cities: action.response.data,
        isLoading: false,
        errors: false,
      };

    case "DELETE_CITY_FAILURE":
      return {
        ...state,
        isLoading: false,
        errors: true,
      };

    case "CITY_NEW":
      return {
        ...state,
        errors: false,
        city: {
          region: null,
          area: null,
          name: null,
          city_type: null,
          full_name: null,
          kladr_id: null,
          fias_id: null,
          okato: null,
          lat: null,
          lon: null,
        },
        isLoading: false,
      };

    case "REQUEST_CITY_SEARCH":
      return update(state, {
        fetchParams: { search: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_CITY_CHANGE_PAGE":
      return update(state, {
        fetchParams: { page: { $set: action.value } },
      });

    case "REQUEST_CITY_CHANGE_LIMIT":
      return update(state, {
        fetchParams: { limit: { $set: action.value }, page: { $set: 1 } },
      });

    case "REQUEST_CITIES_FILTER_ACTIVE":
      return update(state, {
        fetchParams: { is_active: { $set: action.value } },
      });

    case "CITIES_SORT_BY":
      return update(state, {
        fetchParams: {
          sort: { $set: action.field },
          direction: { $set: action.direction },
        },
      });

    case "CITY_CHANGE_REGION":
      return update(state, {
        city: { region: { $set: action.value }, area: { $set: null } },
      });

    case "CITY_CHANGE_AREA":
      return update(state, {
        city: { area: { $set: action.value } },
      });

    case "CITY_CHANGE_NAME":
      return update(state, {
        city: { name: { $set: action.value } },
      });

    case "CITY_CHANGE_CITY_TYPE":
      return update(state, {
        city: { city_type: { $set: action.value } },
      });

    case "CITY_CHANGE_FULL_NAME":
      return update(state, {
        city: { full_name: { $set: action.value } },
      });

    case "CITY_CHANGE_KLADR":
      return update(state, {
        city: { kladr_id: { $set: action.value } },
      });

    case "CITY_CHANGE_FIAS":
      return update(state, {
        city: { fias_id: { $set: action.value } },
      });

    case "CITY_CHANGE_OKATO":
      return update(state, {
        city: { okato: { $set: action.value } },
      });

    case "CITY_CHANGE_LAT":
      return update(state, {
        city: { lat: { $set: action.value } },
      });

    case "CITY_CHANGE_LON":
      return update(state, {
        city: { lon: { $set: action.value } },
      });

    // filters
    case "CITIES_CLEAR_FILTERS":
      return update(state, {
        fetchParams: {
          regions: { $set: null },
          areas: { $set: null },
        },
      });

    case "CITIES_SET_FILTERS":
      return {
        ...state,
      };

    case "CITIES_FILTER_REGIONS":
      return update(state, {
        fetchParams: {
          regions: { $set: action.values.length > 0 ? action.values : null },
          areas: { $set: null },
          page: { $set: 1 },
        },
      });

    case "CITIES_FILTER_AREAS":
      return update(state, {
        fetchParams: {
          areas: { $set: action.values.length > 0 ? action.values : null },
          page: { $set: 1 },
        },
      });

    default:
      return state;
  }
}
