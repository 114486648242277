// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/faqs";
import List from "./List";

class Faqs extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchFaqs());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchFaqs());
      });
    });
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchFaqs()));
  };

  onChangeLimit = (current, size) => {
    this.props
      .dispatch(Actions.onChangeLimit(size))
      .then(() => this.props.dispatch(Actions.fetchFaqs()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchFaqs()));
  };

  render() {
    const { isLoading, faqs, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        faqs={faqs}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onChangeLimit={this.onChangeLimit}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.faqs.isLoading,
  faqs: state.faqs.faqs,
  meta: state.faqs.meta,
  fetchParams: state.faqs.fetchParams
});

export default connect(mapStateToProps)(Faqs);
