// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/faqs";
import { Spin } from "antd";
import Form from "./Form";

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.fetchFaq(this.props.match.params.id));
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(`/faqs/${this.props.faq.id}/edit`);
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/faqs`);
  };

  // general
  onChangeTitle = (e) => {
    this.props.dispatch(Actions.onChangeTitle(e.target.value));
  };

  onChangeBody = (content) => {
    this.props.dispatch(Actions.onChangeBody(content));
  };

  onChangeTags = (values) => {
    this.props.dispatch(Actions.onChangeTags(values));
  };

  // sidebar
  onChangePublished = (value) => {
    // console.log(e)
    this.props.dispatch(Actions.onChangePublished(value));
  };

  onChangeApplicationType = (value) => {
    this.props.dispatch(Actions.onChangeApplicationType(value));
  };

  onChangeContex = (value) => {
    //console.log(value)
    this.props.dispatch(Actions.onChangeContex(value));
  };

  onChangeSubcontext = (value) => {
    this.props.dispatch(Actions.onChangeSubcontext(value));
  };

  render() {
    const { isNew } = this.state;
    const { isLoading, faq } = this.props;
    return isLoading ? (
      <Spin />
    ) : (
      <Form
        isNew={isNew}
        faq={faq}
        onSave={this.onSave}
        onClose={this.onClose}
        onPublish={this.onPublish}
        // general
        onChangeTitle={this.onChangeTitle}
        onChangeBody={this.onChangeBody}
        onChangeTags={this.onChangeTags}
        // sidebar
        onChangePublished={this.onChangePublished}
        onChangeApplicationType={this.onChangeApplicationType}
        onChangeContex={this.onChangeContex}
        onChangeSubcontext={this.onChangeSubcontext}
      />
    );
  }
}

Faq.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  faq: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.faqs.isLoading,
  errors: state.faqs.errors,
  faq: state.faqs.faq,
});

export default connect(mapStateToProps)(withRouter(Faq));
