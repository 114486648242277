// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Table, Button, PageHeader, Badge } from "antd";
import { Pagination } from "antd";
import { Row, Col, Input } from "antd";

import PropTypes from "prop-types";

import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;

class SmsNotificationsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      isFilterVisible: false,
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  onChangeTable = (pagination, filters, sorter) => {
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const { selectedRowKeys, isFilterVisible } = this.state;
    const {
      loading,
      supportMessageTypes,
      meta,
      fetchParams,
      onFetchStatus,
      onSearch,
      onChangePage,
      onChangeLimit,
      onSortBy,
    } = this.props;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "Системный тип",
        dataIndex: "is_common_support",
        render: (is_common_support, record) => (
          <span>
            <Badge status={is_common_support ? "success" : "error"} className={"large"} />
          </span>
        ),
        width: "10%",
        align: "center",
      },
      {
        title: "Наименование",
        dataIndex: "name",
        render: (name, record) => (
          <Link to={`/support_message_types/${record.id}/edit`}>{name}</Link>
        ),
        width: "90%",
        align: "center",
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="delete" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="open"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/support_message_types/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Типы сообщений`}
        extra={[
          <Search
            allowClear
            key="99"
            placeholder="поиск..."
            value={fetchParams.search}
            onChange={onSearch}
            style={{ width: 250 }}
          />,
          mainButton,
        ]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={supportMessageTypes}
                onChange={this.onChangeTable}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                defaultPageSize={fetchParams.limit}
                defaultCurrent={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      ></PageHeader>
    );
  }
}

SmsNotificationsList.propTypes = {
  orders: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
};

export default SmsNotificationsList;
