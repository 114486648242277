// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Form, Input, Tabs, Button } from "antd";

const TabPane = Tabs.TabPane;
const FormItem = Form.Item;

class Header extends Component {
  render() {
    const {
      isNew,
      name,
      title,
      email,
      phone,
      password,
      password_confirmation,
      onChangeName,
      onChangeEmail,
      onChangeTitle,
      onChangePhone,
      onChangePassword,
      onChangePasswordConfirmation,
      onUpdatePassword,
    } = this.props;

    return (
      <React.Fragment>
        <Row gutter={16}>
          <Col span={24}>
            <Tabs defaultActiveKey={"1"}>
              <TabPane tab="Основные данные" key="1">
                <Col span={24}>
                  <Form.Item
                    label={"Имя"}
                    name="name"
                    initialValue={name}
                    onChange={onChangeName}
                    rules={[{ required: true, message: "Введите имя!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={"Email"}
                    name="email"
                    initialValue={email}
                    onChange={onChangeEmail}
                    rules={[{ required: true, message: "Введите email!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={"Должность"}
                    name="title"
                    initialValue={title}
                    onChange={onChangeTitle}
                    rules={[{ required: true, message: "Введите должность!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={"Телефон"}
                    name="phone"
                    initialValue={phone}
                    onChange={onChangePhone}
                    rules={[{ required: true, message: "Введите телефон!" }]}
                  >
                    <Input />
                  </Form.Item>
                </Col>

                {isNew ? (
                  <React.Fragment>
                    <Col span={24}>
                      <Form.Item
                        label={"Пароль"}
                        name="password"
                        initialValue={password}
                        onChange={onChangePassword}
                        rules={[{ required: true, message: "Введите пароль!" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={"Подтверждение пароля"}
                        name="password_confirmation"
                        initialValue={password_confirmation}
                        onChange={onChangePasswordConfirmation}
                        rules={[
                          {
                            required: true,
                            message: "Введите пароль еще раз!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </React.Fragment>
                ) : null}
              </TabPane>
              {!isNew ? (
                <TabPane tab="Смена пароля" key="2">
                  <Row gutter={16}>
                    <Col span={24}>
                      <Form.Item
                        label={"Пароль"}
                        name="password"
                        initialValue={password}
                        onChange={onChangePassword}
                        rules={[{ required: true, message: "Введите пароль!" }]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col span={24}>
                      <Form.Item
                        label={"Подтверждение пароля"}
                        name="password_confirmation"
                        initialValue={password_confirmation}
                        onChange={onChangePasswordConfirmation}
                        rules={[
                          {
                            required: true,
                            message: "Введите пароль еще раз!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={16}>
                    <Col span={24}>
                      <Button onClick={onUpdatePassword} type="primary">
                        Сохранить пароль
                      </Button>
                    </Col>
                  </Row>
                </TabPane>
              ) : null}
            </Tabs>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

Header.propTypes = {};

export default Header;
