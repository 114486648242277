// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row, InputNumber, Tooltip } from "antd";
import { QuestionOutlined } from "@ant-design/icons";

const FormItem = Form.Item;
const { TextArea } = Input;

class General extends Component {
  render() {
    const {
      region,
      onChangeCode,
      onChangeName,
      onChangeRegionType,
      onChangeFullName,
      onChangeFederalDistrict,
      onChangeKladr,
      onChangeFias,
      onChangeOkato,
      onChangeTimeZone,
      onChangeLat,
      onChangeLon,
    } = this.props;

    return (
      <Row gutter={16}>
        <Col span={4}>
          <Form.Item
            label={"Код"}
            name="code"
            initialValue={region.code}
            onChange={onChangeCode}
            // rules={[{ required: true, message: "Введите код!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            label={"Наименование"}
            name="name"
            initialValue={region.name}
            onChange={onChangeName}
            rules={[{ required: true, message: "Введите наименование!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={20}>
          <Form.Item
            label={"Полное наименование"}
            name="full_name"
            initialValue={region.full_name}
            onChange={onChangeFullName}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            label={
              <span>
                Врем. зона&nbsp;
                <Tooltip title="Относительно времени по гринвичу">
                  <QuestionOutlined />
                </Tooltip>
              </span>
            }
            name="time_zone"
            initialValue={region.time_zone}
            onChange={onChangeTimeZone}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Тип региона"}
            name="region_type"
            initialValue={region.region_type}
            onChange={onChangeRegionType}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={16}>
          <Form.Item
            label={"Федеральный округ"}
            name="federal_district"
            initialValue={region.federal_district}
            onChange={onChangeFederalDistrict}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"КЛАДР"}
            name="kladr_id"
            initialValue={region.kladr_id}
            onChange={onChangeKladr}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"ФИАС"}
            name="fias_id"
            initialValue={region.fias_id}
            onChange={onChangeFias}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"ОКАТО"}
            name="okato"
            initialValue={region.okato}
            onChange={onChangeOkato}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Широта"}
            name="lat"
            initialValue={region.lat}
            onChange={onChangeLat}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
        <Col span={8}>
          <Form.Item
            label={"Долгота"}
            name="lon"
            initialValue={region.lon}
            onChange={onChangeLon}
          >
            <InputNumber style={{ width: "100%" }} />
          </Form.Item>
        </Col>
      </Row>
    );
  }
}

General.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default General;
