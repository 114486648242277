import api from "../api";

const Actions = {};

Actions.fetchCompanies = () => {
  return (dispatch, getState) => {
    dispatch({ type: "FETCH_COMPANIES_REQUEST" });
    const params = getState().companies.fetchParams;
    api
      .fetch("/companies", params)
      .then((response) => {
        dispatch({ type: "FETCH_COMPANIES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_COMPANIES_FAILURE", response });
      });
  };
};

Actions.fetchCompany = (id = null) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_COMPANY_REQUEST" });
    api
      .fetch(`/companies/${id}`)
      .then((response) => {
        dispatch({ type: "FETCH_COMPANY", response });
      })
      .catch((error) => {
        api.handleError(error, "Подрядчик");
        dispatch({ type: "FETCH_COMPANY_FAILURE" });
      });
  };
};

Actions.onNew = () => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_NEW",
    });
  };
};

Actions.onUpdate = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: "PATCH_COMPANY_REQUEST" });
    const company = getState().companies.company;

    const data = {
      company: {
        name: company.name,
        inn: company.inn,
        website: company.website,
        postal_code: company.postal_code,
        street: company.street,
        building: company.building,
        city_id: company.city ? company.city.id : null,
      },
    };
    api
      .patch(`/companies/${id}`, data)
      .then((response) => {
        dispatch({ type: "PATCH_COMPANY", response });
        api.openNotification("success", "Подрядчик", "Успешно обновлен!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            return api.openNotification("error", "Подрядчик", error);
          });
        }
        dispatch({ type: "PATCH_COMPANY_FAILURE", response });
      });
  };
};

Actions.onCreate = () => {
  return (dispatch, getState) => {
    dispatch({ type: "POST_COMPANY_REQUEST" });
    const company = getState().companies.company;
    const data = {
      company: {
        name: company.name,
        inn: company.inn,
        website: company.website,
        postal_code: company.postal_code,
        street: company.street,
        building: company.building,
        city_id: company.city ? company.city.id : null,
      },
    };
    return api
      .post(`/companies`, data)
      .then((response) => {
        dispatch({ type: "POST_COMPANY", response });
        api.openNotification("success", "Подрядчик", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Подрядчик", error);
          });
        }
        dispatch({ type: "POST_COMPANY_FAILURE", response });
      });
  };
};

Actions.onDelete = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_COMPANY_REQUEST" });
    return api
      .delete(`/companies/${id}`)
      .then((response) => {
        api.openNotification("success", "Подрядчик", "Успешно удален!");
        dispatch({ type: "DELETE_COMPANY", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Подрядчик", error);
          });
        }
        dispatch({
          type: "DELETE_COMPANY_FAILURE",
        });
      });
  };
};

Actions.onUploadLogo = (companyId, file) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_LOGO_UPLOAD",
    });

    const formData = new FormData();
    formData.append("file", file);
    return api
      .patchFormData(`/companies/${companyId}/logo`, formData)
      .then((response) => {
        api.openNotification(
          "success",
          "Подрядчик",
          "Лого успешно загружено!"
        );
        dispatch({ type: "COMPANY_LOGO_UPLOAD_SUCCEED", response });
      })
      .catch((response) => {
        dispatch({
          type: "COMPANY_LOGO_UPLOAD_FAILED",
        });
        dispatch({
          type: "ADD_NOTIFICATION",
          message: { label: response.error, type: "error" },
        });
      });
  };
};

// employees
Actions.onDeleteEmployees = (id) => {
  return (dispatch) => {
    dispatch({ type: "DELETE_COMPANY_EMPLOYEE_REQUEST" });
    return api
      .delete(`/employees/${id}`)
      .then((response) => {
        api.openNotification("success", "Пользователь", "Успешно удален!");
        dispatch({ type: "DELETE_COMPANY_EMPLOYEE", response });
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({
          type: "DELETE_COMPANY_EMPLOYEE_FAILURE",
        });
      });
  };
};

Actions.onCreateEmployee = (company_id, values) => {
  return (dispatch) => {
    dispatch({ type: "POST_COMPANY_EMPLOYEE_REQUEST" });
    const data = {
      employee: {
        is_active: true,
        role: 1,
        title: values.title,
        email: values.email,
        phone: values.phone,
        name: values.name,
        company_id: company_id,
        password: values.password,
        password_confirmation: values.password_confirmation,
      },
    };
    return api
      .post(`/employees`, data)
      .then((response) => {
        dispatch({ type: "POST_COMPANY_EMPLOYEE", response });
        api.openNotification("success", "Пользователь", "Успешно создан!");
      })
      .catch((response) => {
        if (response.errors) {
          response.errors.map((error) => {
            api.openNotification("error", "Пользователь", error);
          });
        }
        dispatch({ type: "POST_COMPANY_EMPLOYEE_FAILURE", response });
      });
  };
};

Actions.fetchEmployees = (company_id) => {
  return (dispatch) => {
    dispatch({ type: "FETCH_COMPANY_EMPLOYEES_REQUEST" });
    return api
      .fetch("/employees", { company: company_id })
      .then((response) => {
        dispatch({ type: "FETCH_COMPANY_EMPLOYEES", response });
      })
      .catch((response) => {
        console.log(response.error);
        dispatch({ type: "FETCH_COMPANY_EMPLOYEES_FAILURE", response });
      });
  };
};

Actions.onSearch = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_COMPANY_SEARCH",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangePage = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_COMPANIES_CHANGE_PAGE",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onChangeLimit = (value) => {
  return (dispatch) => {
    dispatch({
      type: "REQUEST_COMPANIES_CHANGE_LIMIT",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onClearFilters = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CLEAR_FILTERS",
      value: value,
    });
    return Promise.resolve();
  };
};

Actions.onSortBy = (field, direction) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANIES_SORT_BY",
      field: field,
      direction: direction,
    });
    return Promise.resolve();
  };
};

Actions.onChangeActive = value => {
  return dispatch => {
    dispatch({
      type: "COMPANY_CHANGE_ACTIVE",
      value: value
    });
  };
};

Actions.onChangeName = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_NAME",
      value: value,
    });
  };
};

Actions.onChangeInn = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_INN",
      value: value,
    });
  };
};

Actions.onChangeWebsite = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_WEBSITE",
      value: value,
    });
  };
};

Actions.onChangeCity = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_CITY",
      value: value,
    });
  };
};

Actions.onChangePostalCode = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_POSTAL_CODE",
      value: value,
    });
  };
};

Actions.onChangeStreet = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_STREET",
      value: value,
    });
  };
};

Actions.onChangeBuilding = (value) => {
  return (dispatch) => {
    dispatch({
      type: "COMPANY_CHANGE_BUILDING",
      value: value,
    });
  };
};



export default Actions;
