// @flow
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";


class Dashboard extends Component {
  componentWillMount = () => {
    // this.props.dispatch(Actions.fetchCalendarEvents());
  };

  

  render() {
    const { currentUser } = this.props;
    return <Fragment></Fragment>;
  }
}

const mapStateToProps = (state) => ({
  currentUser: state.session.currentUser,
});

export default connect(mapStateToProps)(Dashboard);
