// @flow
import React, { Component } from "react";
import { Col, Form, Card, Button } from "antd";
import PropTypes from "prop-types";
import General from "./General";

class RegionForm extends Component {
  onFinish = (values) => {
    this.props.onSave(values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  render() {
    

    const {
      isNew,
      isLoading,
      region,
      onClose,
      onChangeCode,
      onChangeName,
      onChangeRegionType,
      onChangeFullName,
      onChangeFederalDistrict,
      onChangeKladr,
      onChangeFias,
      onChangeOkato,
      onChangeTimeZone,
      onChangeLat,
      onChangeLon,
    } = this.props;

    return (
      <Card
        loading={isLoading}
        title={
          <h2 style={{ marginBottom: 0 }}>{`Регион #${
            isNew ? "новый" : region.name
          }`}</h2>
        }
        extra={[
          <Button key={1} onClick={onClose}>
            Закрыть
          </Button>,
          <Button
            key={2}
            onClick={this.onFinish}
            type="primary"
            ghost
            style={{ marginLeft: "8px" }}
          >
            Сохранить
          </Button>,
        ]}
      >
        <Form layout={"vertical"}>
          <Col span={13}>
            <General
              
              region={region}
              onChangeCode={onChangeCode}
              onChangeName={onChangeName}
              onChangeRegionType={onChangeRegionType}
              onChangeFullName={onChangeFullName}
              onChangeFederalDistrict={onChangeFederalDistrict}
              onChangeKladr={onChangeKladr}
              onChangeFias={onChangeFias}
              onChangeOkato={onChangeOkato}
              onChangeTimeZone={onChangeTimeZone}
              onChangeLat={onChangeLat}
              onChangeLon={onChangeLon}
            />
          </Col>
        </Form>
      </Card>
    );
  }
}

RegionForm.propTypes = {
  projectType: PropTypes.object,
};

export default RegionForm;
