// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Input, Col, Row } from "antd";
import { Editor } from "@tinymce/tinymce-react";

import SelectMultiFetch from "../../../components/SelectMultiFetch";

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { content: "" };
    this.onEditorChange = this.onEditorChange.bind(this);
  }

  onEditorChange(content, editor) {
    this.props.onChangeBody(content);
    // this.setState({ content });
  }

  render() {
    const { title, body, tags, onChangeTitle, onChangeTags } = this.props;

    return (
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label={"Заголовок"}
            name="name"
            initialValue={title}
            onChange={onChangeTitle}
            rules={[{ required: true, message: "Введите заголовок!" }]}
          >
            <Input />
          </Form.Item>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <Form.Item
            label={"Теги"}
            name="tags"
            initialValue={tags ? tags : []}
            onChange={onChangeTags}
          >
            <SelectMultiFetch
              showSearch
              placeholder="Теги"
              onChange={onChangeTags}
              selected={tags ? tags : []}
              url={"/tags/search/?'"}
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Editor
            apiKey="n2iws6zuwpna7twgyz38iyrixvx6y2hywnmyty7yi3n1icsh"
            value={body}
            init={{
              plugins: "media, image",
              menubar: "insert",
              // toolbar: "media",
              media_live_embeds: true,
              height: 500,
              // menubar: false,
              content_style: "body { font-size: 11pt; font-weight: 400 }",
              toolbar:
                "undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | \
                 bullist numlist outdent indent | removeformat | media image",
            }}
            onEditorChange={this.onEditorChange}
          />
        </Col>
      </Row>
    );
  }
}

Header.propTypes = {
  entity: PropTypes.object,
  onChangeDate: PropTypes.func,
  onChangeAssignee: PropTypes.func,
  onChangeAgreement: PropTypes.func,
  onChangeAgreementDate: PropTypes.func,
  onChangeAdditional: PropTypes.func,
  onChangeComment: PropTypes.func,
  onChangeNotice: PropTypes.func,
};

export default Header;
