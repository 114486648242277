// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/regions";
import List from "./List";

class Regions extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchRegions());
  };

  onDelete = ids => {
    ids.map(id => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchRegions());
      });
    });
  };

  onChangePage = value => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchRegions()));
  };

  onSearch = e => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchRegions()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchRegions()));
  };

  render() {
    const { isLoading, regions, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        regions={regions}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onSortBy={this.onSortBy}
      />
    );
  }
}

const mapStateToProps = state => ({
  isLoading: state.regions.isLoading,
  regions: state.regions.regions,
  meta: state.regions.meta,
  fetchParams: state.regions.fetchParams
});

export default connect(mapStateToProps)(Regions);
