// @flow
import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import {
  Table,
  Row,
  Col,
  PageHeader,
  Pagination,
  Button,
  Badge,
  Input,
  Select,
  Tag
} from "antd";
import { FilterOutlined } from "@ant-design/icons";
import moment from "moment";
import "moment/locale/ru";

const Search = Input.Search;
const Option = Select.Option;

function optionsTag(services) {
  let options = []
  if (services.tickets) {
    options.push(<Tag key={'tickets'} color="#108ee9">заявки</Tag>)
  }

  if (services.entity_tasks) {
    options.push(<Tag key={'entity_tasks'} color="#108ee9">регламент</Tag>)
  }

  if (services.order_tasks) {
    options.push(<Tag key={'order_tasks'} color="#108ee9">задачи по заказам</Tag>)
  }

  if (services.orders) {
    options.push(<Tag key={'orders'} color="#108ee9">заказы</Tag>)
  }

  if (services.tasks) {
    options.push(<Tag key={'tasks'} color="#108ee9">задачи</Tag>)
  }

  if (services.projects) {
    options.push(<Tag key={'projects'} color="#108ee9">проекты</Tag>)
  }

  if (services.payrolls) {
    options.push(<Tag key={'payrolls'} color="#108ee9">начисления</Tag>)
  }


  return options
}

class tariffPlansList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedRowKeys: [],
      filterActiveVisible: false,
    };
  }

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  onDelete = () => {
    this.props.onDelete(this.state.selectedRowKeys);
    this.setState({ selectedRowKeys: [] });
  };

  // filters
  onChangeFilterActive = (value) => {
    this.setState({ filterActiveVisible: false }, () => {
      this.props.onChangeFilterActive(value);
    });
  };

  onChange = (pagination, filters, sorter) => {
    console.log(sorter);
    let direction = "desc";
    if (sorter.order === "descend") {
      direction = "desc";
    } else if (sorter.order === "ascend") {
      direction = "asc";
    }
    this.props.onSortBy(sorter.field, direction);
  };

  render() {
    const {
      loading,
      tariffPlans,
      meta,
      fetchParams,
      onChangePage,
      onChangeLimit,
      onSearch,
    } = this.props;
    const { selectedRowKeys } = this.state;

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    const hasSelected = selectedRowKeys.length > 0;

    const columns = [
      {
        title: "#",
        dataIndex: "is_active",
        render: (is_active, record) => (
          <span>
            <Badge status={is_active ? "success" : "error"} />
          </span>
        ),
        key: "is_active",
        width: "5%",
        align: "center",
        responsive: ['lg', 'md'],
      },
      {
        title: "Наименование",
        dataIndex: "name",
        sorter: true,
        sortDirections: ["ascend", "descend"],
        render: (name, record) => (
          <Link to={`/tariff_plans/${record.id}/edit`}>{record.name}</Link>
        ),
        sorter: (a, b) => a.name.length - b.name.length,
        key: "name",
        width: "25%",
        align: "center",
      },
      {
        title: "Опции",
        dataIndex: "options",
        render: (services, record) => (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <span>{optionsTag(record.services)}</span>
          </div>
        ),
        key: "options",
        width: "40%",
        align: "center",
        responsive: ['lg', 'md'],
      },
      {
        title: "Кол-во пользователей",
        dataIndex: "users_qty",
        key: "users_qty",
        width: "10%",
        align: "center",
      },
      {
        title: "Стоимость",
        dataIndex: "price",
        key: "price",
        width: "10%",
        align: "center",
      },
      {
        title: "Дата создания",
        dataIndex: "created_at",
        render: (created_at) => moment(created_at).format("L"),
        key: "created_at",
        width: "10%",
        align: "center",
        responsive: ['lg', 'md'],
      },
    ];

    let mainButton = hasSelected ? (
      <Button key="1" type="danger" ghost onClick={this.onDelete}>
        Удалить
      </Button>
    ) : (
      <Link
        key="2"
        className={"ant-btn ant-btn-primary ant-btn-background-ghost"}
        to={{ pathname: `/tariff_plans/new` }}
      >
        Создать
      </Link>
    );

    return (
      <PageHeader
        onBack={() => window.history.back()}
        title={`Тарфные планы`}
        extra={[mainButton]}
        footer={
          <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
            <Col span={24}>
              <Table
                bordered
                rowSelection={rowSelection}
                size={"middle"}
                loading={loading}
                pagination={false}
                columns={columns}
                dataSource={tariffPlans}
                onChange={this.onChange}
                rowKey="id"
              />
            </Col>
            <Col
              span={24}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                textAlign: "right",
              }}
            >
              <Pagination
                showSizeChanger
                pageSize={fetchParams.limit}
                current={meta.current_page}
                onChange={onChangePage}
                onShowSizeChange={onChangeLimit}
                showTotal={(total) => `Всего: ${meta.total_count}`}
                total={meta.total_count}
              />
            </Col>
          </Row>
        }
      />
    );
  }
}

tariffPlansList.propTypes = {
  tariffPlans: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
  meta: PropTypes.object,
  fetchParams: PropTypes.object,
  onSearch: PropTypes.func,
  onChangePage: PropTypes.func,
};

export default tariffPlansList;
