// @flow
import React, { Component, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import Actions from "../../../actions/companies";
import LoadingSpin from "../../../components/LoadingSpin";

const Form = lazy(() => import("./Form"));
const renderLoader = () => <LoadingSpin />;

class Company extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isNew: false,
    };
  }

  componentWillMount = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(
        Actions.fetchCompany(this.props.match.params.id)
      );
    } else {
      this.setState({ isNew: true }, () => {
        this.props.dispatch(Actions.onNew());
      });
    }
  };

  onSave = () => {
    if (this.props.match.params.id) {
      this.props.dispatch(Actions.onUpdate(this.props.match.params.id));
    } else {
      this.props.dispatch(Actions.onCreate()).then(() => {
        if (!this.props.errors) {
          this.props.history.push(
            `/companies/${this.props.company.id}/edit`
          );
        }
      });
    }
  };

  onClose = () => {
    this.props.history.push(`/companies`);
  };

  onUploadLogo = (file) => {
    this.props.dispatch(Actions.onUploadLogo(this.props.company.id, file));
  };

  onChangeName = (e) => {
    this.props.dispatch(Actions.onChangeName(e.target.value));
  };

  onChangeInn = (e) => {
    this.props.dispatch(Actions.onChangeInn(e.target.value));
  };

  onChangeWebsite = (e) => {
    this.props.dispatch(Actions.onChangeWebsite(e.target.value));
  };

  onChangeCity = (value) => {
    this.props.dispatch(Actions.onChangeCity(value));
  };

  onChangePostalCode = (e) => {
    this.props.dispatch(Actions.onChangePostalCode(e.target.value));
  };

  onChangeStreet = (e) => {
    this.props.dispatch(Actions.onChangeStreet(e.target.value));
  };

  onChangeBuilding = (e) => {
    this.props.dispatch(Actions.onChangeBuilding(e.target.value));
  };

  // user
  onDeleteEmployees = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDeleteEmployees(id)).then(() => {
        this.props.dispatch(Actions.fetchEmployees(this.props.match.params.id));
      });
    });
  };

  onSaveEmployee = (values) => {
    if (values.id) {
      this.props
        .dispatch(Actions.onUpdateEmployee(this.props.match.params.id, values))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(Actions.fetchEmployees(this.props.match.params.id));
          }
        });
    } else {
      this.props
        .dispatch(Actions.onCreateEmployee(this.props.match.params.id, values))
        .then(() => {
          if (!this.props.errors) {
            this.props.dispatch(Actions.fetchEmployees(this.props.match.params.id));
          }
        });
    }
  };


  render() {
    const {
      isLoading,
      isUploadingLogo,
      isEmployeesLoading,
      errors,
      company,
      statistic,
    } = this.props;

    return (
      <Suspense fallback={renderLoader()}>
        <Form
          isNew={this.state.isNew}
          isLoading={isLoading}
          errors={errors}
          company={company}
          statistic={statistic}
          onSave={this.onSave}
          onClose={this.onClose}
          // general
          onChangeActive={this.onChangeActive}
          isUploadingLogo={isUploadingLogo}
          onUploadLogo={this.onUploadLogo}
          onChangeName={this.onChangeName}
          onChangeInn={this.onChangeInn}
          onChangeWebsite={this.onChangeWebsite}
          onChangeCity={this.onChangeCity}
          onChangePostalCode={this.onChangePostalCode}
          onChangeStreet={this.onChangeStreet}
          onChangeBuilding={this.onChangeBuilding}
          // employees
          isEmployeesLoading={isEmployeesLoading}
          onDeleteEmployees={this.onDeleteEmployees}
          onSaveEmployee={this.onSaveEmployee}
          // tariff_plan
        />
      </Suspense>
    );
  }
}

Company.propTypes = {
  dispatch: PropTypes.func,
  match: PropTypes.object,
  company: PropTypes.object,
  isLoading: PropTypes.bool,
  errors: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  isLoading: state.companies.isLoading,
  isUploadingLogo: state.companies.isUploadingLogo,
  errors: state.companies.errors,
  company: state.companies.company,
  // employees
  isEmployeesLoading: state.companies.isEmployeesLoading,
});

export default connect(mapStateToProps)(withRouter(Company));
