// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Row, Col, Form, Select, Switch } from "antd";
import moment from "moment";
import "moment/locale/ru";

const { Option, OptGroup } = Select;

class Sidebar extends Component {
  render() {
    const {
      isPublished,
      applicationType,
      contex,
      subcontext,
      onChangePublished,
      onChangeApplicationType,
      onChangeContex,
      onChangeSubcontext,
    } = this.props;

    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={10}>
          <Col span={24}>
            <Form.Item label="Опубликовать" name="isPublished">
              <Switch
                checked={isPublished}
                onChange={onChangePublished}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Приложение"}
              name="applicationType"
              initialValue={applicationType}
              rules={[{ required: true, message: "Выберите приложение!" }]}
            >
              <Select
                allowClear
                placeholder="Приложение"
                optionFilterProp="children"
                filterOption={false}
                value={applicationType}
                onChange={onChangeApplicationType}
              >
                <Option value={"ssa"}>SSA</Option>
                <Option value={"ssc"}>SSC</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Контекст"}
              name="contex"
              initialValue={contex}
              rules={[{ required: true, message: "Выберите контекст!" }]}
            >
              <Select
                allowClear
                value={contex}
                onChange={onChangeContex}
                placeholder="Контекст"
                optionFilterProp="children"
                filterOption={false}
              >
                <OptGroup label="Основное">
                  <Option value={1}>Рабочий стол</Option>
                  <Option value={2}>Заявки</Option>
                  <Option value={3}>Регламент</Option>
                  <Option value={4}>Проекты</Option>
                  <Option value={5}>Задачи</Option>
                </OptGroup>
                <OptGroup label="Заказы">
                  <Option value={20}>Заказы</Option>
                  <Option value={21}>Задачи по заказам</Option>
                </OptGroup>
                <OptGroup label="Финансы">
                  <Option value={30}>Начисления</Option>
                  <Option value={31}>Базовые начисления</Option>
                  <Option value={32}>Схема начислений</Option>
                </OptGroup>
                <OptGroup label="Отчеты">
                  <Option value={40}>SLA выполнения</Option>
                  <Option value={41}>SLA эффективности</Option>
                  <Option value={42}>Коэффициент занятости</Option>
                  <Option value={43}>Выполнение плана</Option>
                  <Option value={44}>Ежедневный отчет</Option>
                  <Option value={45}>Задачи</Option>
                </OptGroup>
                <OptGroup label="Справочники">
                  <Option value={60}>Клиенты</Option>
                  <Option value={61}>Объекты</Option>
                  <Option value={62}>Организация</Option>
                  <Option value={63}>Услуги</Option>
                  <Option value={64}>Пользователи</Option>
                  <Option value={65}>Пользователи (клиентские)</Option>
                  <Option value={66}>Новости</Option>
                  <Option value={67}>Поддержка</Option>
                  <Option value={68}>Типы проектов</Option>
                  <Option value={69}>Этапы проектов</Option>
                  <Option value={70}>Шаблоны задач</Option>
                  <Option value={71}>Регионы</Option>
                </OptGroup>
              </Select>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              label={"Субконтекст"}
              name="subcontext"
              initialValue={subcontext}
              rules={[{ required: true, message: "Выберите субконтекст!" }]}
            >
              <Select
                allowClear
                value={subcontext}
                onChange={onChangeSubcontext}
                placeholder="Субкодконтекст"
                optionFilterProp="children"
                filterOption={false}
              >
                <Option value={1}>Список</Option>
                <Option value={2}>Форма</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Card>
    );
  }
}
Sidebar.propTypes = {
  customer: PropTypes.object,
  getFieldDecorator: PropTypes.func,
  onAdressEdit: PropTypes.func,
  onChangePaymentType: PropTypes.func,
  onChangePaymentStatus: PropTypes.func,
  onChangeShippingStatus: PropTypes.func,
  onChangeShippingMethod: PropTypes.func,
};

export default Sidebar;
