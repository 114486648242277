// @flow
import React, { Component } from "react";
import { connect } from "react-redux";
import Actions from "../../../actions/cities";
import List from "./List";

class Cities extends Component {
  componentWillMount = () => {
    this.props.dispatch(Actions.fetchCities());
  };

  onDelete = (ids) => {
    ids.map((id) => {
      this.props.dispatch(Actions.onDelete(id)).then(() => {
        this.props.dispatch(Actions.fetchCities());
      });
    });
  };

  onChangePage = (value) => {
    this.props
      .dispatch(Actions.onChangePage(value))
      .then(() => this.props.dispatch(Actions.fetchCities()));
  };

  onSearch = (e) => {
    this.props
      .dispatch(Actions.onSearch(e.target.value))
      .then(() => this.props.dispatch(Actions.fetchCities()));
  };

  onSortBy = (field, direction) => {
    this.props
      .dispatch(Actions.onSortBy(field, direction))
      .then(() => this.props.dispatch(Actions.fetchCities()));
  };

  // filters
  onClearFilters = () => {
    this.props.dispatch(Actions.onClearFilters()).then(() =>
      this.props.dispatch(Actions.fetchCities())
    );
  };

  onChangeFilterRegions = values => {
    this.props.dispatch(Actions.onChangeFilterRegions(values)).then(() =>
      this.props.dispatch(Actions.fetchCities())
    );
  };

  onChangeFilterAreas = values => {
    this.props.dispatch(Actions.onChangeFilterAreas(values)).then(() =>
      this.props.dispatch(Actions.fetchCities())
    );
  };

  render() {
    const { isLoading, cities, meta, fetchParams } = this.props;
    return (
      <List
        loading={isLoading}
        cities={cities}
        meta={meta}
        fetchParams={fetchParams}
        onDelete={this.onDelete}
        onSearch={this.onSearch}
        onChangePage={this.onChangePage}
        onSortBy={this.onSortBy}
        // filters
        onClearFilters={this.onClearFilters}
        onChangeFilterRegions={this.onChangeFilterRegions}
        onChangeFilterAreas={this.onChangeFilterAreas}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  isLoading: state.cities.isLoading,
  cities: state.cities.cities,
  meta: state.cities.meta,
  fetchParams: state.cities.fetchParams,
});

export default connect(mapStateToProps)(Cities);
