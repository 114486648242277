// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col, Card, Form, Checkbox, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";

function getBase64(file, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(file);
}

class Statuses extends Component {
  // onUpload = ({ onSuccess, onError, file }) => {
  //   this.props.onUploadAvatar(file);
  // };

  onUpload = ({ onSuccess, onError, file }) => {
    const { onTempFileUpload } = this.props;

    getBase64(file, (fileUrl) =>
      onTempFileUpload({
        id: file.uid,
        name: file.name,
        content_type: file.type,
        file_size: file.size,
        attachment: fileUrl,
      })
    );
  };

  render() {
    const { isNew, avatar, isUploadingAvatar, isФctive, onChangeActive } =
      this.props;

    const uploadButton = (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          flexFlow: "column",
          Height: "140px",
          Width: "140px",
        }}
      >
        {isUploadingAvatar ? <LoadingOutlined /> : <PlusOutlined />}

        <div>Загрузить</div>
      </div>
    );
    return (
      <Card style={{ borderColor: "#1890ff" }}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="isФctive"
              initialValue={isФctive}
              valuePropName="checked"
              onChange={onChangeActive}
            >
              <Checkbox>активен</Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Upload
              name="url"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              customRequest={this.onUpload}
            >
              {avatar ? (
                <img height={"100"} width={"100"} src={avatar} alt="avatar" />
              ) : (
                uploadButton
              )}
            </Upload>
          </Col>
        </Row>
      </Card>
    );
  }
}
Statuses.propTypes = {
  getFieldDecorator: PropTypes.func,
};

export default Statuses;
