// @flow
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Col, Row, Select, Form } from "antd";
import SelectMultiFetch from "../../../components/SelectMultiFetch";

const FormItem = Form.Item;

class Filter extends Component {
  render() {
    const {
      visible,
      regions,
      areas,
      onChangeRegions,
      onChangeAreas,
    } = this.props;

    return visible ? (
      <Card size="small" title="Фильтры">
        <Row gutter={{ md: 8, lg: 24, xl: 48 }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Регионы"}
              name="regions"
              initialValue={regions ? regions : []}
              onChange={onChangeRegions}
            >
              <SelectMultiFetch
                showSearch
                placeholder="Регионы"
                onChange={onChangeRegions}
                selected={regions ? regions : []}
                url={"/regions/search/?"}
              />
            </Form.Item>
          </Col>

          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <Form.Item
              label={"Районы"}
              name="areas"
              initialValue={areas ? areas : []}
              onChange={onChangeAreas}
            >
              <SelectMultiFetch
                showSearch
                placeholder="Районы"
                onChange={onChangeAreas}
                selected={areas ? areas : []}
                url={"/regions/search/?"}
              />
            </Form.Item>
          </Col>
        </Row>
      </Card>
    ) : null;
  }
}

Filter.propTypes = {
  entity: PropTypes.object,
};

export default Filter;
